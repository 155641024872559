const DeviceDuotoneIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.09233 5.36263C6.39615 3.8435 7.73 2.75 9.27922 2.75H14.7208C16.27 2.75 17.6038 3.84349 17.9077 5.36262L20.3077 17.3626C20.7099 19.3737 19.1717 21.25 17.1208 21.25H6.87921C4.82831 21.25 3.29011 19.3737 3.69233 17.3626L6.09233 5.36263Z"
      fill="#71B48D"
      fillOpacity="0.39"
      stroke="#525C7A"
      strokeWidth="1.5"
    />
    <circle cx="17" cy="18" r="1" fill="#525C7A" />
    <line
      x1="4"
      y1="14.25"
      x2="20"
      y2="14.25"
      stroke="#525C7A"
      strokeWidth="1.5"
    />
    <line
      x1="7"
      y1="18"
      x2="13"
      y2="18"
      stroke="#525C7A"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default DeviceDuotoneIcon;
