import { TextInputField, Pane, toaster, Button, Card } from 'evergreen-ui'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import './Registration.css'
import { Link } from 'react-router-dom'

const RegistrationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email').required('Email is required'),
  password: Yup.string().min(2, 'Too Short!').required('Password is required'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ).required('Password confirmation is required'),
})

export default function RegistrationForm({ handleSubmit, email }) {
  return (
    <Formik
      initialValues={{
        email: email || "",
        password: "",
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={RegistrationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(props) => {
        return (
          <Form onSubmit={props.handleSubmit}>
            <TextInputField
              id="email"
              name="email"
              onChange={props.handleChange}
              isInvalid={!!props.errors.email}
              validationMessage={props.errors.email || null}
              label={
                <p style={{ margin: 0, fontSize: 12, color: '#343A40' }}>
                  Email
                </p>
              }
              placeholder="jane@acme.com"
            />
            <TextInputField
              id="password"
              name="password"
              type="password"
              onChange={props.handleChange}
              isInvalid={!!props.errors.password}
              validationMessage={props.errors.password || null}
              label={
                <p style={{ margin: 0, fontSize: 12, color: '#343A40' }}>
                  Password
                </p>
              }
              placeholder="******"
            />
            <TextInputField
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              onChange={props.handleChange}
              isInvalid={!!props.errors.passwordConfirmation}
              validationMessage={props.errors.passwordConfirmation || null}
              label={
                <p style={{ margin: 0, fontSize: 12, color: '#343A40' }}>
                  Password Confirmation
                </p>
              }
              placeholder="******"
            />
            <Button type="submit" className="register-button">
              Submit
            </Button>
            <p className="login-text">
              Already have an account?{' '}
              <Link to="/login" className="reset-password-link">
                Login
              </Link>
            </p>
          </Form>
        )
      }}
    </Formik>
  )
}
