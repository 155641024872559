import  { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Pane } from 'evergreen-ui';

import './appDownloadBanner.css';
import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from '../../utils/constants'; 

export const AppDownloadBanner = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false)
  }
  
  const handleClick = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();

        if (/iphone|ipad/.test(userAgent)) {
          // Uncomment this line when the iOS app is ready
          // window.location.href = { IOS_DOWNLOAD_LINK };
          
          // Until we get the ios link, redirect to links
          history.push("/links");
        } else if (/android/.test(userAgent)) {
          window.location.href = { ANDROID_DOWNLOAD_LINK };
        } else {
          history.push("/links");
        }
  };

  return (
    <Pane className={visible ? "banner" : "banner hidden"}>
      <button className="banner-button" onClick={handleClick}>
        <p className="banner-text">EXPERIENCE SEAMLESS DATA ACCESS WITH THE JUNIPER MOBILE APP.</p>
      </button>
      <button className="close-button" onClick={handleClose}>X</button>
    </Pane>
  );
};