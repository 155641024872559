import {
  IconButton,
  majorScale,
  Pane,
  PlusIcon,
  Table,
  Button,
  EyeOpenIcon,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { useHistory, Switch, Route } from "react-router";
import { CustomerDevice } from "../../models/customer_devices";
import Device from "./Device";
import CommonTopPageHeader from "../../shared/CommonTopPageHeader";
import CommonCard from "../../shared/CommonCard";
import "./Devices.css";
import NewDeviceSideSheet from './NewDeviceSideSheet';
import { useLocation } from "react-router-dom";
import CountUp from 'react-countup'

export default function DevicesPage() {
  return (
    <Pane display="flex" flex="1" flexDirection="column">
      <Switch>
        <Route exact path="/dashboard/devices" component={DevicesList} />
        <Route exact path="/dashboard/devices/new" component={DevicesList} />
        <Route path="/dashboard/devices/:id" component={Device} />

      </Switch>
    </Pane>
  );
}

function DevicesList() {
  const history = useHistory()
  const location = useLocation()
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    getDeviceData();
  }, [location.pathname]);

  async function getDeviceData() {
    let { data } = await CustomerDevice.all();
    setDevices(data);
  }
  function goToOnboardDevice() {
    history.push('/dashboard/devices/new')
  }

  return (
    <>
      <Pane
        display="flex"
        flexDirection="column"
        overflowY="scroll"
        minHeight="100vh"
        className="hidden-scrollbar"
      >
        <CommonTopPageHeader>
          <h1 style={{ margin: 0, verticalAlign: "middle" }}>
          Devices
          </h1>
          <div>
            <Button
              iconBefore={PlusIcon}
              intent="none"
              onClick={goToOnboardDevice}
              marginLeft={10}
              height={42}
            >
            Add New Device
            </Button>
          </div>
        </CommonTopPageHeader>
        <div className="auto-columns">
          <DeviceInfoPanel devices={devices} />
        </div>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body flex="1">{devices.map((device) => <DeviceRow device={device} history={history} key={device.id} />)}</Table.Body>
        </Table>
      </Pane>
      <Route exact path="/dashboard/devices/new" component={NewDeviceSideSheet} />
    </>
  );
}

function DeviceInfoPanel({ devices }) {
  return (
    <Pane
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      marginBottom="1rem"
    >
      <CommonCard>
        <h2 className="device-card-label">Device Count</h2>
        <p className="device-card-value"><CountUp end={devices?.length || 0} duration={0.40}/></p>
      </CommonCard>
    </Pane>
  );
}

function DeviceRow({device, history}) {
  function goToDeviceView() {
    history.push(`/dashboard/devices/${device.id}`);
  }
  return (
    <Table.Row>
      <Table.TextCell>{device.name}</Table.TextCell>
      <Table.TextCell>{device.description}</Table.TextCell>
      <Table.TextCell>
        <IconButton
          icon={(iconProps) => (
            <EyeOpenIcon
              {...iconProps}
              tab-index="-1"
              aria-label={`Edit Device ${device.name}`}
            />
          )}
          marginRight={majorScale(2)}
          intent="none"
          onClick={goToDeviceView}
        />
      </Table.TextCell>
    </Table.Row>
  );
}
