import ApplicationRecord from "./application";
import { attr, hasMany, hasOne, belongsTo } from "spraypaint/dist/spraypaint";

const Alert = ApplicationRecord.extend({
  static: {
    jsonapiType: "alerts",
    active: function () {
      return this.where({ status: "active" });
    },
  },
  attrs: {
    alertableType: attr(),
    name: attr(),
    status: attr(),
    jsonRule: attr()
  },
});

export { Alert };
