const DeviceFilledIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.27917 0C5.37244 0 3.73078 1.34584 3.35684 3.21554L1.69995 11.5H18.2999L16.6431 3.21554C16.2691 1.34584 14.6275 0 12.7207 0H7.27917Z"
      fill="#525C7A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.956844 15.2155L1.39995 13H18.6L19.0431 15.2155C19.5381 17.6907 17.6449 20 15.1207 20H4.87917C2.35498 20 0.461811 17.6907 0.956844 15.2155ZM15 17C15.5522 17 16 16.5523 16 16C16 15.4477 15.5522 15 15 15C14.4477 15 14 15.4477 14 16C14 16.5523 14.4477 17 15 17ZM4.99995 15C4.44767 15 3.99995 15.4477 3.99995 16C3.99995 16.5523 4.44767 17 4.99995 17H11C11.5522 17 12 16.5523 12 16C12 15.4477 11.5522 15 11 15H4.99995Z"
      fill="#525C7A"
    />
  </svg>
);

export default DeviceFilledIcon;
