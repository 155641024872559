const AreasFilledIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 14.2871C3.14864 15.1341 2 16.3145 2 17.6201C2 20.1976 6.47715 22.2871 12 22.2871C17.5228 22.2871 22 20.1976 22 17.6201C22 16.3145 20.8514 15.1341 19 14.2871"
      stroke="#525C7A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19C16 19 19 12 19 8C19 4 16 1 12 1C8 1 5 4 5 8C5 12 8 19 12 19ZM12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z"
      fill="#525C7A"
    />
  </svg>
);

export default AreasFilledIcon;
