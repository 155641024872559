import ApplicationRecord from "./application";
import { attr, belongsTo } from "spraypaint/dist/spraypaint";

const Sensor = ApplicationRecord.extend({
  static: {
    jsonapiType: "sensor_readings",
    latest: function () {
      return this
        .order({ timestamp: "desc" })
    },
  },
  attrs: {
    timestamp: attr(),
    name: attr(),
    value: attr(),
    unit: attr(),
    boardSerial: attr(),
    createdAt: attr({ persist: false }),
    updatedAt: attr({ persist: false }),
    organization: belongsTo(),
    facility: belongsTo(),
  },
});

export { Sensor };
