import "./appDownloadBtns.css"
import google from "../../assets/images/google-play-badge.svg"
import apple from "../../assets/images/apple-store-badge.svg";
import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from "../../utils/constants";

export default function AppDownloadBtns() {
  return (
    <div className="download-links">
      <a 
        target="_blank"
        className="download-link"
        href={ ANDROID_DOWNLOAD_LINK } 
        rel="noreferrer"
      >
        <img 
          className="download-badge" 
          src={google}
          alt="google play link"
        />
      </a>
      <a target="_blank" href={ IOS_DOWNLOAD_LINK } rel="noreferrer">
        <img 
          className="download-badge" 
          src={apple}
          alt="apple store link"
        />
      </a>
    </div>
  )
}
