import { useContext, useEffect } from 'react';
import { IconButton, NotificationsIcon, Pane } from 'evergreen-ui';
import { GlobalContext } from '../store/globalStore';

import styles from "./TopNavigation.module.css"
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const TopNavigation = observer(() => {
  const globalStore = useContext(GlobalContext);
  const alerts = globalStore.alerts
  useEffect(() => {
    globalStore.getAlertLogs();
    globalStore.subscribe()
    return () => {
      globalStore.unsubscribe()
    }
  }, []);

  const unreadAlerts = globalStore.alerts.filter(x => !x.hasBeenRead)
  
  return <Pane className={styles["top-navigation"]}>
    <Link className={styles["notification-link"]} to="/dashboard/notifications">
      <NotificationsIcon color={unreadAlerts.length > 0 ? '#9F2D64' : "#000"} />
      {unreadAlerts.length > 0 && (
        <p>{unreadAlerts.length}</p>
      )}
    </Link>
  </Pane>
})

export default TopNavigation