import { useEffect, useState, useContext } from "react";

import { Pane, toaster } from "evergreen-ui";
import { Category } from "../models/category";
import GenericInfoPanel from "../components/info-panels/generic-info-panel";
import CommonTopPageHeader from "../shared/CommonTopPageHeader";
import { User } from "../models/user";
import { CustomerDevice } from "../models/customer_devices";
import { useLocation } from 'react-router';
import CurrentUser, { tokenWithPrefix } from "../hooks/currentUser";
import baseUrl from '../utils/base_url'
import { GlobalContext } from "../store/globalStore";
import { Organization } from "../models/organization";


export default function DashboardPage() {
  const currentUser = CurrentUser()
  const [areas, setAreas] = useState(null);
  const [users, setUsers] = useState(null);
  const [devices, setDevices] = useState(null);
  const location = useLocation()
  const parsedQ = new URLSearchParams(location.search)
  const stripeId = parsedQ.get('stripeId')


  useEffect(() => {
    getDeviceData()
    saveStripeId();
  }, [stripeId, currentUser]);

  async function saveStripeId() {
    if (stripeId && currentUser) {
      const url = baseUrl + `/rest/v1/stripe/set_stripe_id`
      const token = await localStorage.getItem(tokenWithPrefix);
      let res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          session_id: stripeId,
        })
      })

      if (res) {
        await res.json()
        toaster.success('Subscription was created successfully!')
      }
    }
  }

  async function getDeviceData() {
    let { data: orgData} = await Organization.first();
    let { meta: areaMeta } = await Category.areas().per(0).where({ organization_id: orgData.id}).all();
    let { meta: userMeta } = await User.stats({ total: "count" }).per(0).all();
    let { meta: deviceMeta } = await CustomerDevice.stats({ total: "count" })
      .per(0)
      .all();

    setAreas(areaMeta);
    setUsers(userMeta);
    setDevices(deviceMeta);
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      overflowY="scroll"
      minHeight="100vh"
      flex="1"
    >
      <CommonTopPageHeader>
        <h1 style={{ margin: 0, verticalAlign: "middle"  }}>Dashboard</h1>
      </CommonTopPageHeader>
      <div
        className="auto-column"
      >
        <GenericInfoPanel label={"Areas"} count={areas?.stats?.total?.count} />
        <GenericInfoPanel label={"Users"} count={users?.stats?.total?.count} />
        <GenericInfoPanel
          label={"Devices"}
          count={devices?.stats?.total?.count}
        />
      </div>
    </Pane>
  );
}
