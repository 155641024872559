/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useHistory, Route, Switch } from "react-router";
import {
  Button,
  Card,
  EditIcon,
  IconButton,
  MoreIcon,
  Pane,
  SelectMenu,
  Spinner,
  TagInput,
  Menu,
  ListIcon,
  NotificationsIcon,
  Popover,
  Position,
  Table,
  ChartIcon,
} from "evergreen-ui";
import moment from "moment-timezone";

import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import SensorChart from "../../components/charts/sensor-chart";
import { Category } from "../../models/category";
import { CustomerDevice } from "../../models/customer_devices";
import _ from "lodash";
import CommonTopPageHeader from "../../shared/CommonTopPageHeader";
import EditDeviceSideSheet from "../../shared/EditDeviceSidesheet";

import "./Device.css";
import NoSensors from "../../components/empty_states/no_sensors";
import CurrentUser, { tokenWithPrefix } from "../../hooks/currentUser";
import DeviceLogs from "./DeviceLogs/DeviceLogs";
import DeviceFilledIcon from "../../assets/icons/devices/device_filled";
import DeviceStatus from "../../components/deviceStatus";
import DeviceAlerts from "./DeviceAlerts/DeviceAlerts";
import { DeviceContext } from "./DeviceStore";
import currentSubUrl from "../../utils/subscription_url";
import ActionCable from "action-cable-react-jwt";

import DeviceReports from "./DeviceReports/DeviceReports";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Device = observer(() => {
  const mobxDevice = useContext(DeviceContext);
  const user = CurrentUser();
  const { device } = mobxDevice;
  const [areas, setAreas] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const history = useHistory();
  const [cable, setCable] = useState(null);
  const params = useParams();
  const [savingCategories, setSavingCategories] = useState(false);
  const [Socket, setSocket] = useState(null);
  const [deviceReadings, setDeviceReadings] = useState([]);

  useEffect(() => {
    if (!params.id) return;
    mobxDevice.getDevice(params.id);
    getAreas();
  }, []);

  useEffect(() => {

    if(mobxDevice.loading) return
    hydrateInitialDeviceReadings();
  }, [mobxDevice.loading])

  useEffect(() => {
    if (!params.id || cable) return;
    let userToken = localStorage.getItem(tokenWithPrefix);
    let App = {};
    App.cable = ActionCable.createConsumer(`${currentSubUrl}/cable`, userToken);

    App.DeviceClaimSubscription = App.cable.subscriptions.create(
      { channel: "DeviceClaimChannel", id: params.id },
      {
        connected: () => {
          console.log("Device Claims connected");
        },
        disconnected: () => {},
        received: (data) => {
          console.log("data", data);
        },
      }
    );

    App.DeviceReadingsSubscription = App.cable.subscriptions.create(
      { channel: "DeviceReadingsChannel", id: params.id },
      {
        connected: () => {
          console.log("Device Readings connected");
        },
        disconnected: () => {},
        received: (data) => {
          setDeviceReadings([...deviceReadings, data]);
        },
      }
    );

    setSocket(App);
    return () => {
      if (App.DeviceClaimSubscription) {
        App?.cable?.subscriptions?.remove(App.DeviceClaimSubscription);
      }
      if (App.DeviceReadingsSubscription) {
        App?.cable?.subscriptions?.remove(App.DeviceReadingsSubscription);
      }

      App.cable.disconnect();
    };
  }, []);

  async function hydrateInitialDeviceReadings() {
    let groupByTimestamp = _.groupBy(mobxDevice?.device?.data?.sensorReadings, 'timestamp');
    let uniqueNamePerGroup = _.map(groupByTimestamp, (group) => {
      return _.uniqBy(group, 'name');
    });
    
    let readings = uniqueNamePerGroup?.map((reading) => {
      return {
        timestamp: moment(reading[0].timestamp).unix(),
        readings: reading
      };
    });


    setDeviceReadings(readings);
  }

  function onClick() {
    Socket.DeviceClaimSubscription.send({ customer_device_id: params.id });
  }

  async function getAreas() {
    let inboundAreas = await Category.areas().all();
    setAreas(inboundAreas.data);
  }

  async function handleRemoveCategory(value) {
    setSavingCategories(true);

    let scope = CustomerDevice.includes(["categories"]);

    let category = device.data.categories.findIndex((a) => a.id === value.id);
    if (category === -1) return setSavingCategories(false);
    device.data.categories[category].isMarkedForDisassociation = true;
    await device.data.save({ with: "categories", returnScope: scope });
    await mobxDevice.refreshDevice();
    setSavingCategories(false);
  }

  async function handleAddCategory(value) {
    setSavingCategories(true);

    let scope = CustomerDevice.includes(["categories"]);

    // we have to copy all the previous values identically to append new category for
    // some reasons
    let cd = device.data.dup();
    let category = areas.find((a) => a.id === value.id);
    cd.categories = [...cd.categories, category];
    await cd.save({ with: "categories", returnScope: scope });
    await mobxDevice.getDevice(params.id);
    setSavingCategories(false);
  }

  function goToEdit() {
    history.push(`/dashboard/devices/${device.data.id}/edit`);
  }

  const areaOptions = useMemo(() => {
    if (!areas || !areas.length) return;
    return areas.map((ar) => {
      return {
        ...ar,
        label: ar.name,
        value: ar.id,
      };
    });
  }, [areas]);

  if (mobxDevice.loading)
    return (
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        overflowY="scroll"
        height="100vh"
        className="hidden-scrollbar"
      >
        <Spinner size={32} />
      </Pane>
    );

  return (
    <>
      <Pane
        display="flex"
        flexDirection="column"
        overflowY="scroll"
        height="100vh"
        className="hidden-scrollbar"
      >
        {device.data && (
          <CommonTopPageHeader>
            <h1 style={{ margin: 0, verticalAlign: "middle" }}>
              Device: {device.data.name}
            </h1>
            {/* <Button
                intent="none"
                onClick={onClick}
                marginLeft={10}
                height={42}
              >
                Test send
              </Button> */}
            <div>
              <Button
                iconBefore={EditIcon}
                intent="none"
                onClick={goToEdit}
                marginLeft={10}
                height={42}
              >
                Edit
              </Button>
              <Popover
                position={Position.BOTTOM_LEFT}
                content={({ close }) => (
                  <Menu>
                    <Menu.Group>
                      <Menu.Item
                        is={Link}
                        icon={ListIcon}
                        to={`/dashboard/devices/${device.data.id}/logs`}
                        onClick={close}
                      >
                        Device Logs
                      </Menu.Item>
                      {user?.attributes?.subscriptions.length > 0 ? (
                        <Menu.Item
                          is={Link}
                          icon={NotificationsIcon}
                          to={`/dashboard/devices/${device.data.id}/alerts`}
                          onClick={close}
                        >
                          Device Alerts
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          is={Link}
                          icon={NotificationsIcon}
                          to={`/dashboard/settings/billing`}
                          onClick={close}
                        >
                          Upgrade to plus for Alerts!
                        </Menu.Item>
                      )}
                      <Menu.Item
                        is={Link}
                        icon={
                          <DeviceFilledIcon
                            width={16}
                            height={16}
                            style={{ display: "flex", flex: 1 }}
                          />
                        }
                        to={`/dashboard/devices/${device.data.id}`}
                        onClick={close}
                      >
                        Device Details
                      </Menu.Item>
                      <Menu.Item
                        is={Link}
                        icon={
                          <ChartIcon
                            width={16}
                            height={16}
                            style={{ display: "flex", flex: 1 }}
                          />
                        }
                        to={`/dashboard/devices/${device.data.id}/report`}
                        onClick={close}
                      >
                        Device Report
                      </Menu.Item>
                    </Menu.Group>
                  </Menu>
                )}
              >
                <IconButton
                  icon={(args) => (
                    <MoreIcon
                      {...args}
                      style={{ transform: "rotate(90deg)" }}
                      aria-label="View more information about device."
                    />
                  )}
                  intent="none"
                  onClick={goToEdit}
                  height={42}
                  appearance="minimal"
                />
              </Popover>
            </div>
          </CommonTopPageHeader>
        )}
        <Switch>
          <Route path={`/dashboard/devices/:id`} exact>
            {deviceReadings?.length > 0 && (
              <CustomerDeviceCard
                deviceReadings={deviceReadings}
                setSelectedSensor={setSelectedSensor}
                selectedSensor={selectedSensor}
              />
            )}
            {deviceReadings?.length === 0 && (
              <>
                <NoSensors id={device.data?.id} />
              </>
            )}
            <Pane display="flex" flex={1} flexDirection="column">
              <Pane
                display="flex"
                flexDirection="row"
                flex="3"
                justifyContent="space-between"
              >
                <DeviceInfoCard
                  device={device.data}
                  areaOptions={areaOptions}
                  handleAddCategory={handleAddCategory}
                  handleRemoveCategory={handleRemoveCategory}
                  isSavingCategories={savingCategories}
                />
                <Card
                  elevation={1}
                  flex={2}
                  height={460}
                  marginY={24}
                  padding={16}
                  marginLeft={24}
                  paddingX={"2rem"}
                  backgroundColor="#fff"
                >
                  <h3>Graph</h3>
                  <Pane
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    paddingTop={12}
                  >
                    {selectedSensor && device.data && (
                      <SensorChart
                        sensor={selectedSensor}
                        customerDevice={device.data}
                      />
                    )}
                    {!selectedSensor &&
                      device.data?.sensorReadings?.length !== 0 && (
                      <h4>Select a sensor to see a graph!</h4>
                    )}
                    {!selectedSensor &&
                      device.data?.sensorReadings?.length === 0 && (
                      <h4>No data yet...</h4>
                    )}
                  </Pane>
                </Card>
              </Pane>
            </Pane>
            <Pane display="flex" flex={1} flexDirection="column">
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Timestamp</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Readings</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  <DeviceReadingsRow deviceReadings={deviceReadings} />
                </Table.Body>
              </Table>
            </Pane>
          </Route>
          <Route path={`/dashboard/devices/:id/logs`} component={DeviceLogs} />
          <Route
            path={`/dashboard/devices/:id/alerts`}
            component={DeviceAlerts}
          />
          <Route
            exact
            path="/dashboard/devices/:id/report"
            component={DeviceReports}
          />
        </Switch>
      </Pane>
      <Route
        path="/dashboard/devices/:id/edit"
        component={(props) => (
          <EditDeviceSideSheet {...props} device={device.data} />
        )}
      />
    </>
  );
});

function DeviceReadingsRow({ deviceReadings }) {
  if(!deviceReadings) return null;
  return deviceReadings?.map((deviceReading) => {
    return (
      <Table.Row key={deviceReading.timestamp} height="auto" paddingY={12}>
        <Table.TextCell>
          {moment(deviceReading.timestamp * 1000).format("MM/DD/YYYY h:mm A")}
        </Table.TextCell>
        <Table.TextCell>
          {deviceReading?.readings?.map((reading) => {
            return (
              <div key={reading.name}>
                <span style={{ marginRight: 5 }}>{reading.name}</span>
                <span>
                  <strong>
                    {reading.value} {reading.unit}
                  </strong>
                </span>
              </div>
            );
          })}
        </Table.TextCell>
      </Table.Row>
    );
  });
}

function DeviceInfoCard(props) {
  if (!props.device) return null;
  return (
    <Card
      elevation={1}
      float="left"
      flex={1}
      height={460}
      marginY={24}
      display="div"
      backgroundColor="#fff"
      padding={16}
      className="device-info-card"
    >
      <h3>Device Info</h3>
      <Pane display="flex" flexDirection="column">
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flex="2"
        >
          <p className="pt-sm info-panel-label">Name:</p>
          <p className="pt-sm info-panel-value">{props.device.name}</p>
        </Pane>
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flex="2"
        >
          <p className="pt-sm info-panel-label">Status:</p>
          <DeviceStatus
            customer_device_id={
              props.device.deviceClaim
                ? props.device.deviceClaim.juniperDeviceId
                : props.device.id
            }
            classNames="pt-sm info-panel-value"
          />
        </Pane>
        <Pane>
          <p className="pt-sm info-panel-label">Description:</p>
          {props.device.description && (
            <p className="info-panel-description-text">
              {props.device.description}
            </p>
          )}
        </Pane>
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flex="2"
        >
          <p className="pt-sm info-panel-label">Version:</p>
          <p className="pt-sm info-panel-value">1.0</p>
        </Pane>
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flex="2"
        >
          <p className="pt-sm info-panel-label">Organization:</p>
          <p className="pt-sm info-panel-value">
            {props.device?.organization?.name}
          </p>
        </Pane>
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          flex="2"
        >
          <p className="pt-sm info-panel-label">Areas:</p>
          <div className="pt-sm info-panel-area-value">
            <SelectMenu
              isMultiSelect
              title="Select Areas"
              options={props.areaOptions}
              selected={
                props.device.categories &&
                props.device.categories.map((x) => x.id)
              }
              isloading={props.savingCategories}
              onSelect={props.handleAddCategory}
              onDeselect={props.handleRemoveCategory}
            >
              <TagInput
                inputProps={{ placeholder: "Select Areas..." }}
                values={props.device.categories.map((x) => x.name)}
              />
            </SelectMenu>
          </div>
        </Pane>
      </Pane>
    </Card>
  );
}

export function CustomerDeviceCard({
  deviceReadings,
  setSelectedSensor,
  selectedSensor,
}) {
  if (!deviceReadings) return [];
  // get last item in array
  const latest = deviceReadings[deviceReadings.length - 1];

  return (
    <section
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(325px, 1fr))",
        gap: "10px",
      }}
    >
      {latest?.readings.sort((a,b) => b.name > a.name ? -1 : 1).map((reading) => {
        if(!reading) return null;
        return (
          <Card
            elevation={selectedSensor?.name === reading.name ? 4 : 1}
            float="left"
            width={325}
            height={170}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            key={reading.name}
            boxShadow={
              selectedSensor?.name === reading.name
                ? "0 0 1px rgb(88 141 90 / 43%), 0 16px 24px -8px rgb(88 141 90 / 43%)"
                : "0 0 1px rgb(67 90 111 / 30%), 0 2px 4px -2px rgb(67 90 111 / 47%)"
            }
            border={
              selectedSensor?.name === reading.name ? "default" : "muted"
            }
            backgroundColor="#fff"
            onClick={() => setSelectedSensor(reading)}
          >
            <h2 className="sensor-reading-label">
              {reading.name.charAt(0).toUpperCase() + reading.name.slice(1)}
            </h2>
            <p className="sensor-reading-value">
              {`${parseFloat(reading.value).toFixed(2)}`}{" "}
              <sup>{`${reading.unit}`}</sup>
            </p>
            <p className="sensor-reading-date">
              {moment.unix(latest.timestamp).format("LTS")}
            </p>
          </Card>
        );
      })}
    </section>
  );
}

export default Device;
