import { Card } from "evergreen-ui";

export default function CommonCard({ children, onClick, key }) {
  return (
    <Card
      elevation={1}
      float="left"
      width={325}
      height={170}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      key={key}
      backgroundColor="#fff"
      onClick={onClick}
    >
      {children}
    </Card>
  );
}
