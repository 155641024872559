import ApplicationRecord from "./application";
import { attr } from "spraypaint/dist/spraypaint";

const Board = ApplicationRecord.extend({
  static: {
    jsonapiType: "boards",
  },
  attrs: {
    name: attr(),
    description: attr()
  },
});

export { Board };
