import { useEffect, useState } from 'react';
import { Button, Pane, PlusIcon, Table } from "evergreen-ui";
import CurrentUser from "../../../hooks/currentUser";
import { useLocation, useHistory } from 'react-router';
import { OrganizationCredentials } from '../../../models/organization_credential';
import { grantTypes } from '../../../utils/organizationCredentials';

export default function InternalCredentials(props) {  
  const user = CurrentUser();
  const location = useLocation()
  const history = useHistory()
  const [credentials, setCredentials] = useState([])

  useEffect(() => {
    getOrgCredentials()
  }, [location.pathname])

  
  async function getOrgCredentials() {
    let { data } = await OrganizationCredentials.all()

    setCredentials(data)
  }

  if (!user) return null;


  return (
    <> 
      <Pane
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
      >
        <h2>Credentials</h2>
        <Button
          iconBefore={PlusIcon}
          intent="none"
          onClick={() => history.push("/dashboard/settings/credentials/new")}
          marginLeft={10}
          height={42}
        >
      Create Credentials
        </Button>
      </Pane>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Type</Table.TextHeaderCell>
          <Table.TextHeaderCell>Scope</Table.TextHeaderCell>
          <Table.TextHeaderCell>Key</Table.TextHeaderCell>

          <Table.TextHeaderCell>Created At</Table.TextHeaderCell>

        </Table.Head>
        <Table.Body flex="1">{credentials.map(CredentialRow)}</Table.Body>
      </Table>
    </>
  );
}

function CredentialRow(credential) {
  return (
    <Table.Row key={credential.id}>
      <Table.TextCell>{credential.grantType}</Table.TextCell>
      <Table.TextCell>{credential.grantScope}</Table.TextCell>
      <Table.TextCell>{credential.key}</Table.TextCell>
      <Table.TextCell>{credential.createdAt}</Table.TextCell>

      {/* <Table.TextCell>
        <IconButton
          icon={(iconProps) => (
            <EyeOpenIcon
              {...iconProps}
              tab-index="-1"
              aria-label={`Show Area ${area.name}`}
            />
          )}
          marginRight={majorScale(2)}
          intent="none"
          onClick={goToArea}
        />
      </Table.TextCell> */}
    </Table.Row>
  )
}