import { createContext } from 'react'
import { makeAutoObservable, observable, runInAction } from "mobx"
import { Organization } from '../models/organization';
import { AlertLog } from '../models/alertLog';
import ActionCable from 'action-cable-react-jwt';
import { tokenWithPrefix } from '../hooks/currentUser';
import currentSubUrl from '../utils/subscription_url';
export const GlobalContext = createContext()


export class GlobalStore {
  loading = false
  alerts = observable([])
  organization_id = null
  Socket = {
    cable: null,
    device_alert_log: null
  }

  constructor() {
    makeAutoObservable(this, {
      alerts: observable,
      loading: observable
    })
  }

  async getAlertLogs() {
    runInAction(async () => {
      this.loading = true
      let alerts = await AlertLog.all();
      this.alerts.replace(alerts.data)
      this.loading = false
    })
  }

  async subscribe() {
    if (!this.organization_id) await this.setOrganization()

    // binding on runinaction causing issues with reference to this, naturally
    this.handleSubscription()
  }

  async handleSubscription() {
    let userToken = await localStorage.getItem(tokenWithPrefix);
    this.Socket.cable = ActionCable.createConsumer(
      `${currentSubUrl}/cable`,
      userToken
    );

    this.Socket.device_alert_log = this.Socket.cable.subscriptions.create(
      { channel: "DeviceAlertsChannel", id: this.organization_id },
      {
        connected: () => {
          console.log('connected to websocket');
        },
        disconnected: () => {
          console.log('disconnect from server,refresh page or check device connection')
        },
        received: (data) => {
          try {
            let decodedData = JSON.parse(data.payload)

            let exists = this.alerts.find(alert => alert.id === decodedData.id)
            if(exists) return
            this.alerts.replace([decodedData, ...this.alerts])
          } catch(err) {
            console.log('Error: couldnt parse data', err)
          }
        }
      }
    );
  }

  async unsubscribe() {
    if(this.Socket?.device_alert_log) {
      this.Socket?.cable?.subscriptions?.remove(this.Socket.device_alert_log);
      this.Socket.cable.disconnect();
    }
  }

  async setOrganization() {
    let org = await Organization.first();
    this.organization_id = org.data.id
  }
}