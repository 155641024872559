import { Pane, toaster, Card } from 'evergreen-ui'
import { useHistory, useLocation } from 'react-router'
import { Registration } from '../../models/registration'
import logo from '../../assets/images/Juniper_Badge_Outline_Black.svg'
import './Registration.css'
import { loadStripe } from '@stripe/stripe-js'
import { Credentials } from '../../models/credential'
import ApplicationRecord from '../../models/application'
import { tokenWithPrefix } from '../../hooks/currentUser'
import baseUrl from '../../utils/base_url'
import RegistrationForm from './RegistrationForm'

let stripePromise = null

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
  }
  return stripePromise
}

export default function RegistrationPage() {
  const history = useHistory()
  const location = useLocation()
  const parsedQ = new URLSearchParams(location.search)
  const email = parsedQ.get('email')
  const intent = parsedQ.get('intent')

  const checkoutOptions = {
    successUrl: `${window.location.origin}/dashboard?stripeId={CHECKOUT_SESSION_ID}`,
    cancelUrl: `${window.location.origin}/dashboard`,
  }

  async function getSession() {
    const url = baseUrl + `/rest/v1/stripe/create_session`
    const token = await localStorage.getItem(tokenWithPrefix)
    let res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...checkoutOptions,
      }),
    })

    if (res) {
      return res.json()
    }

    return null
  }

  async function redirectToCheckout() {
    const { session } = await getSession()
    const stripe = await getStripe()

    const { error } = await stripe.redirectToCheckout({ sessionId: session.id })

    if (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (values) => {

    let registration = new Registration({
      email: values.email,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    })

    try {
      await registration.save()
    } catch (error) {
      console.error(error);
      toaster.warning(
        'There was a server error. Please try again later.'
      )
    }
      
    let creds = new Credentials({
      email: values.email,
      password: values.password,
    })
    
    try {
      await localStorage.setItem('token', null)
      ApplicationRecord.jwt = null

      await creds.save()
      if (creds.errors?.base) {
        toaster.warning(creds.errors.base.message)
        toaster.warning(
          'There was a server error. Please try again later.'
        )
        return
      }
      await localStorage.setItem(tokenWithPrefix, creds.jsonWebToken)

      ApplicationRecord.jwt = creds.jsonWebToken

      if (intent) {
        redirectToCheckout()
      } else {
        history.push('/dashboard')
      }
    } catch (error) {
      toaster.warning(
        'There was a server error. Please try again later.'
      )
    }
  }

  return (
    <Pane className="registration-pane">
      <Card className="registration-card" elevation={2}>
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop={60}
          marginBottom={32}
        >
          <img
            src={logo}
            height={39}
            width={33}
            alt="Juniper Technology Logo"
          />
          <h1 style={{ margin: 10 }}>Welcome</h1>
          <p style={{ color: '#868E96', textAlign: 'center', margin: 10 }}>
            Fill in your credentials to create an account
          </p>
        </Pane>
        <RegistrationForm handleSubmit={handleSubmit} email={email} />
      </Card>
    </Pane>
  )
}
