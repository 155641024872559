import { useState, useEffect } from "react";
import {
  Button,
  Heading,
  Pane,
  SideSheet,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router";
import { Organization } from "../models/organization";

const OrganizationSchema = Yup.object().shape({
  name: Yup.string().required("Organization name is required!"),
  address: Yup.string().min(2, "Too Short!").required("Address is required!"),
  primaryPhone: Yup.string()
    .min(2, "Too Short!")
    .required("Primary Phone is required!"),
  primaryEmail: Yup.string()
    .email()
    .required("Organization Email is required!"),
});

export default function EditOrganizationSideSheet() {
  let { id } = useParams();
  const history = useHistory();
  const [organization, setOrganization] = useState(null);
  useEffect(() => {
    if (id) getOrgData(id);
  }, [id]);

  async function getOrgData(id) {
    let data = await Organization.find(id);
    setOrganization(data.data);
  }
  if (!organization) return null;
  return (
    <SideSheet isShown={true} onCloseComplete={() => history.goBack()}>
      <Pane margin={20}>
        <Heading size={800}>Edit: {organization.name}</Heading>
        <Pane display="flex" flexDirection="column" marginTop={25}>
          <Formik
            initialValues={{
              name: organization.name,
              address: organization.address,
              primaryPhone: organization.primaryPhone,
              primaryEmail: organization.primaryEmail,
            }}
            validationSchema={OrganizationSchema}
            onSubmit={async (values) => {
              let org = (await Organization.find(id)).data;
              org.name = values.name || organization.name;
              org.address = values.address || organization.address;
              org.primaryPhone =
                values.primaryPhone || organization.primaryPhone;
              org.primaryEmail =
                values.primaryEmail || organization.primaryEmail;
              await org.save();
              if (org.errors.base) {
                toaster.warning(org.errors.base.message);
              }

              toaster.success(`${org.name} has been updated!`);
              history.goBack();
            }}
          >
            {(props) => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  <TextInputField
                    id="name"
                    name="name"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.name}
                    validationMessage={props.errors.name || null}
                    label="Organization Name:"
                    placeholder={props.initialValues.name}
                    type="text"
                  />
                  <TextInputField
                    id="address"
                    name="address"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.address}
                    validationMessage={props.errors.address || null}
                    label="Organization Address:"
                    placeholder={props.initialValues.address}
                    type="text"
                  />
                  <TextInputField
                    id="primaryPhone"
                    name="primaryPhone"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.primaryPhone}
                    validationMessage={props.errors.primaryPhone || null}
                    label="Primary Phone:"
                    placeholder={props.initialValues.primaryPhone}
                    type="tel"
                  />
                  <TextInputField
                    id="primaryEmail"
                    name="primaryEmail"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.primaryEmail}
                    validationMessage={props.errors.primaryEmail || null}
                    label="Email address:"
                    placeholder={props.initialValues.primaryEmail}
                    type="email"
                  />
                  <Pane display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Pane>
                </Form>
              );
            }}
          </Formik>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
