import { Card, Pane, Heading, Button, MoreIcon, IconButton, EditIcon } from "evergreen-ui";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import SensorChart from "../../components/charts/sensor-chart";
import { Category } from "../../models/category";
import { Sensor } from "../../models/sensor";
import CommonTopPageHeader from "../../shared/CommonTopPageHeader";
import GenericInfoPanel from "../../components/info-panels/generic-info-panel";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function Area() {
  const [area, setArea] = useState(null);
  const [devices, setCustomerDevices] = useState([]);
  const params = useParams();
  const history = useHistory();
  const getAreaData = useCallback(async () => {
    let { data } = await Category.includes("customer_devices").find(params.id);

    setArea(data);
    setCustomerDevices(data.customerDevices);
  }, [params.id])

  useEffect(() => {
    if (!params.id) return;
    getAreaData();
  }, [getAreaData, params.id]);
  

  function goToEdit() {
    history.push(`/dashboard/areas/${area.id}/edit`);
  }

  if(!area) return <></>;

  return (
    <>
      <Pane
        display="flex"
        flexDirection="column"
        overflowY="scroll"
        minHeight="100vh"
        flex="1"
      >
        <CommonTopPageHeader>
          <h1 style={{ margin: 0, verticalAlign: "middle" }}>Area: {area.name}</h1>
          {/* <div>
            <Button
              iconBefore={EditIcon}
              intent="none"
              onClick={goToEdit}
              marginLeft={10}
              height={42}
            >
              Edit
            </Button>
            <IconButton
              icon={(args) => (
                <MoreIcon
                  {...args}
                  style={{ transform: "rotate(90deg)" }}
                  aria-label="View more information about device."
                />
              )}
              intent="none"
              onClick={goToEdit}
              height={42}
              appearance="minimal"
            />
          </div> */}
        </CommonTopPageHeader>
        <GenericInfoPanel label={"Devices"} count={devices?.length} />
        <Pane paddingX={20}>
          {devices.length > 0 && devices.map((device) => {
            return <CustomerDeviceCard device={device} />;
          })}
        </Pane>
      </Pane>
    </>
  );
}

function CustomerDeviceCard({ device }) {
  const [sensorReadings, setSensorReadings] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);
  
  const fetchSensorReadingsForDevice = useCallback(async () => {
    let readings = await Sensor.latest()
      .where({ customer_device_id: device.id })
      .all();
    let flattenedValues = readings.data.reduce((hash, item) => {
      if (!hash[item.name] || hash[item.name].timestamp >= item.timestamp) {
        hash[item.name] = { ...item };
        return hash;
      }
      return hash;
    }, {});
    setSensorReadings(flattenedValues);
  }, [device.id])

  useEffect(() => {
    fetchSensorReadingsForDevice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    // Your custom logic here
    fetchSensorReadingsForDevice();
  }, 30000);

  if (!sensorReadings) return <></>;
  return (
    <Pane>
      <Heading size={800} paddingTop="1rem" paddingBottom="1rem">{device.name}</Heading>
      <section style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gap: '1rem 0.1rem' }} >
        {Object.keys(sensorReadings).length > 0 &&
          Object.keys(sensorReadings).map((key) => {
            let reading = sensorReadings[key];
            return (
              <Card
                elevation={1}
                float="left"
                width={200}
                height={135}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                key={reading.id}
                backgroundColor="#fff"
              >
                <h2 className="sensor-reading-label">
                  {reading.name.charAt(0).toUpperCase() + reading.name.slice(1)}
                </h2>
                <p className="sensor-reading-value">
                  {`${parseFloat(reading.value).toFixed(2)}`}{" "}
                  <sup>{`${reading.unit}`}</sup>
                </p>
                <p className="sensor-reading-date">
                  {moment(reading.timestamp).format("ll")}
                </p>
              </Card>
            );
          })}
      </section>
    </Pane>
  );
}
