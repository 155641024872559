import ApplicationRecord from "./application";
import { attr, hasMany } from "spraypaint/dist/spraypaint";

const Organization = ApplicationRecord.extend({
  static: {
    jsonapiType: "organizations",
  },
  attrs: {
    name: attr(),
    address: attr(),
    primaryPhone: attr(),
    primaryEmail: attr(),
    createdAt: attr({ persist: false }),
    updatedAt: attr({ persist: false }),
    sensor_readings: hasMany(),
    facilities: hasMany(),
  },
});

export { Organization };
