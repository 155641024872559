import { useState, useEffect } from "react";
import {
  Button,
  Heading,
  Pane,
  SideSheet,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { CustomerDevice } from "../../models/customer_devices";
import { Board } from "../../models/board";
import { Organization } from "../../models/organization";

const DeviceSchema = Yup.object().shape({
  name: Yup.string().required("Device name is required!"),
  description: Yup.string(),
});

export default function NewDeviceSideSheet() {
  const history = useHistory();
  const [org, setOrg] = useState([]);
  useEffect(() => {
    fetchOrganizations();
  },[])

  async function fetchOrganizations() {
    let { data: orgData} = await Organization.first();
    setOrg(orgData);
  }
  return (
    <SideSheet isShown={true} onCloseComplete={() => history.goBack()}>
      <Pane margin={20}>
        <Heading size={800}>Create a new Device</Heading>
        <Pane display="flex" flexDirection="column" marginTop={25}>
          <Formik
            initialValues={{
              name: '',
              description: ''
            }}
            validationSchema={DeviceSchema}
            onSubmit={async (values) => {
              let dev = new CustomerDevice({...values})
              let cusBoard = new Board({...values})
              dev.board = cusBoard
              dev.organization = org
              await dev.save({ with: ['board', 'organization'] });
              if (dev.errors.base) {
                toaster.warning(dev.errors.base.message);
              }
              
              toaster.success(`${dev.name} has been updated!`);
              history.goBack();
            }}
          >
            {(props) => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  <TextInputField
                    id="name"
                    name="name"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.name}
                    validationMessage={props.errors.name || null}
                    label="Device Name:"
                    placeholder={'Device Name'}
                    type="text"
                  />
                  <TextInputField
                    id="description"
                    name="description"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.description}
                    validationMessage={props.errors.description || null}
                    label="Device Description:"
                    placeholder={'Device Description'}
                    type="text"
                  />
                  <Pane display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Pane>
                </Form>
              );
            }}
          </Formik>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
