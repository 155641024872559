import {
  Button,
  Heading,
  Pane,
  SideSheet,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Organization } from "../models/organization";

const OrganizationSchema = Yup.object().shape({
  name: Yup.string().required("Organization name is required!"),
  address: Yup.string().min(2, "Too Short!").required("Address is required!"),
  primaryPhone: Yup.string()
    .min(2, "Too Short!")
    .required("Primary Phone is required!"),
  primaryEmail: Yup.string()
    .email()
    .required("Organization Email is required!"),
});

export default function CreateOrganizationSideSheet() {
  const history = useHistory();
  return (
    <SideSheet isShown={true} onCloseComplete={() => history.goBack()}>
      <Pane margin={20}>
        <Heading size={800}>Create a new Organization</Heading>
        <Pane display="flex" flexDirection="column" marginTop={25}>
          <Formik
            initialValues={{
              name: "",
              address: "",
              primaryPhone: "",
              primaryEmail: "",
            }}
            validationSchema={OrganizationSchema}
            onSubmit={async (values) => {
              let org = new Organization({
                name: values.name,
                email: values.email,
                primaryPhone: values.primaryPhone,
                primaryEmail: values.primaryEmail,
              });
              await org.save();
              if (org.errors.base) {
                toaster.warning(org.errors.base.message);
              }

              toaster.success(`${org.name} has been created!`);
              history.goBack();
            }}
          >
            {(props) => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  <TextInputField
                    id="name"
                    name="name"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.name}
                    validationMessage={props.errors.name || null}
                    label="Organization Name:"
                    placeholder="Acme Inc."
                    type="text"
                  />
                  <TextInputField
                    id="address"
                    name="address"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.address}
                    validationMessage={props.errors.address || null}
                    label="Organization Address:"
                    placeholder="123 yellow brick road"
                    type="text"
                  />
                  <TextInputField
                    id="primaryPhone"
                    name="primaryPhone"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.primaryPhone}
                    validationMessage={props.errors.primaryPhone || null}
                    label="Primary Phone:"
                    placeholder="555-555-5555"
                    type="tel"
                  />
                  <TextInputField
                    id="primaryEmail"
                    name="primaryEmail"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.primaryEmail}
                    validationMessage={props.errors.primaryEmail || null}
                    label="Email address:"
                    placeholder="jane@acme.com"
                    type="email"
                  />
                  <Pane display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Pane>
                </Form>
              );
            }}
          </Formik>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
