import { Link } from "react-router-dom";
import './linksTreeBtns.css';

export default function LinksTreeBtn(props) {
  const { variant = 'primary', path, onClick, text, external, children } = props;
  
  let className = "links-button"; 
  switch (variant) {
    case 'primary':
      className += " links-primary-button";
      break;
    case 'secondary':
      className += " links-secondary-button";
      break;
    default:
      className += " links-primary-button";
  }

  if (external) {
    return (
      <a href={path} className={className} onClick={onClick} target="_blank">
        {text}
        {children}
      </a>
    )
  } else {
    return (
      <Link to={path} className={className} onClick={onClick}>
        {text}
        {children}
      </Link>
    )
  }
}
