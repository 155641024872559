const HomeDuotoneIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.36497 10.4132C4.31768 10.0192 4.46034 9.62608 4.74936 9.35407L11.1433 3.33625C11.6246 2.88325 12.3754 2.88325 12.8567 3.33625L19.2506 9.35407C19.5397 9.62608 19.6823 10.0192 19.635 10.4132L18.4667 20.149C18.3914 20.7773 17.8584 21.2501 17.2256 21.2501H6.77435C6.1416 21.2501 5.60864 20.7773 5.53325 20.149L4.36497 10.4132Z"
      fill="#71B48D"
      fillOpacity="0.39"
      stroke="#525C7A"
      strokeWidth="1.5"
    />
    <path
      d="M2 12L12 2L22 12"
      stroke="#525C7A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HomeDuotoneIcon;
