import { useState, useEffect } from "react";

import {
  Button,
  Heading,
  Label,
  Pane,
  Select,
  SelectField,
  SideSheet,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Organization } from "../models/organization";
import { OrganizationCredentials } from "../models/organization_credential";
import { grantScopes, grantTypes } from "../utils/organizationCredentials";

const OrganizationSchema = Yup.object().shape({
  grantType: Yup.string().required("Grant Type is required!"),
  grantScope: Yup.string().required("Grant Scope is required!")
});

export default function CreateOrganizationCredentialSideSheet() {
  const history = useHistory();
  const [org, setOrg] = useState(null)
  useEffect(() => {
    fetchOrganizations();
  },[])

  async function fetchOrganizations() {
    let { data: orgData} = await Organization.first();
    setOrg(orgData);
  }
  if(!org) return null
  return (
    <SideSheet isShown={true} onCloseComplete={() => history.goBack()}>
      <Pane margin={20}>
        <Heading size={800}>Create new Credentials</Heading>
        <Pane display="flex" flexDirection="column" marginTop={25}>
          <Formik
            initialValues={{
              grantType: "mqtt",
              grantScope: "",
              metaData: ""
            }}
            validationSchema={OrganizationSchema}
            onSubmit={async (values) => {
              let orgCred = new OrganizationCredentials({
                grantType: values.grantType,
                grantScope: values.grantScope
              });
              orgCred.organization = org
              await orgCred.save({ with: ['organization'] });
              if (orgCred.errors.base) {
                toaster.warning(orgCred.errors.base.message);
              }

              toaster.success(`${orgCred.name} has been created!`);
              history.goBack();
            }}
          >
            {(props) => {
              let currentGrantType = props.values.grantType || props.initialValues.grantType
              return (
                <Form onSubmit={props.handleSubmit}>
                  <SelectField label="Grant Type" defaultValue="mqtt" onChange={event => props.setFieldValue('grantType',event.target.value)}>
                    {grantTypes.map((grant) =>{
                      return (
                        <option value={grant} key={grant}>
                          {grant}
                        </option>
                      )
                    })}
                  </SelectField>
                  
                    
                  <SelectField label="Grant Scope" value={props.values.grantScope} onChange={event => props.setFieldValue('grantScope',event.target.value)}>
                    {grantScopes[currentGrantType]?.map((scope) => {
                      return (
                        <option value={scope} key={scope}>
                          {scope}
                        </option>
                      )
                    })}
                  </SelectField>
                  <Pane display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Pane>
                </Form>
              );
            }}
          </Formik>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
