const LogoutIcons = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4 1.25H8.367H8.36698C7.27485 1.24999 6.40935 1.24999 5.71173 1.30699C4.99835 1.36527 4.39472 1.48688 3.84355 1.76772C2.94978 2.22312 2.22312 2.94978 1.76772 3.84355C1.48688 4.39472 1.36527 4.99835 1.30699 5.71173C1.24999 6.40935 1.24999 7.27485 1.25 8.36698V8.367V8.4V15.6V15.633V15.633C1.24999 16.7252 1.24999 17.5906 1.30699 18.2883C1.36527 19.0017 1.48688 19.6053 1.76772 20.1565C2.22312 21.0502 2.94978 21.7769 3.84355 22.2323C4.39472 22.5131 4.99835 22.6347 5.71173 22.693C6.40935 22.75 7.27485 22.75 8.36698 22.75H8.4H9.5H10C10.4142 22.75 10.75 22.4142 10.75 22C10.75 21.5858 10.4142 21.25 10 21.25H9.5H8.4C7.26752 21.25 6.46327 21.2494 5.83388 21.198C5.21325 21.1473 4.829 21.0509 4.52453 20.8958C3.913 20.5842 3.41582 20.087 3.10423 19.4755C2.94909 19.171 2.85271 18.7867 2.80201 18.1661C2.75058 17.5367 2.75 16.7325 2.75 15.6V8.4C2.75 7.26752 2.75058 6.46327 2.80201 5.83388C2.85271 5.21325 2.94909 4.829 3.10423 4.52453C3.41582 3.913 3.913 3.41582 4.52453 3.10423C4.829 2.94909 5.21325 2.85271 5.83388 2.80201C6.46327 2.75058 7.26752 2.75 8.4 2.75H9.5H10C10.4142 2.75 10.75 2.41421 10.75 2C10.75 1.58579 10.4142 1.25 10 1.25H9.5H8.4ZM18.5303 7.46967C18.2374 7.17678 17.7626 7.17678 17.4697 7.46967C17.1768 7.76256 17.1768 8.23744 17.4697 8.53033L20.1893 11.25H10C9.58579 11.25 9.25 11.5858 9.25 12C9.25 12.4142 9.58579 12.75 10 12.75H20.1893L17.4697 15.4697C17.1768 15.7626 17.1768 16.2374 17.4697 16.5303C17.7626 16.8232 18.2374 16.8232 18.5303 16.5303L22.5303 12.5303C22.8232 12.2374 22.8232 11.7626 22.5303 11.4697L18.5303 7.46967Z"
      fill="#525C7A"
    />
  </svg>
);

export default LogoutIcons;
