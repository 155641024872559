const grantTypes = ['mqtt']

const grantScopes = {
  'mqtt': ['publish', 'subscribe', 'pubsub']
}

// attribute :grant_type, :string_enum, allow: [:mqtt, :api, :grpc]
// attribute :grant_scope, :string_enum, allow: [:read, :write, :read_write, :publish, :subscribe, :pubsub]


export {
  grantTypes,
  grantScopes
}