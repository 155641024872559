import ApplicationRecord from "./application";
import { attr, hasMany, hasOne, belongsTo } from "spraypaint/dist/spraypaint";
import { DeviceClaims } from "./device_claims";

const CustomerDevice = ApplicationRecord.extend({
  static: {
    jsonapiType: "customer_devices",
  },
  attrs: {
    id: attr(),
    name: attr(),
    description: attr(),
    hasReadings: attr(),
    online: attr(),
    sensorTypeOptions: attr(),
    sensorReadings: hasMany(),
    deviceClaim: hasOne({ type: DeviceClaims }),
    categories: hasMany(),
    board: hasOne(),
    organization: belongsTo(),
    alerts: hasMany()
  },
});

export { CustomerDevice };