export const jsonRulesOperators = [
  {
    label: '>=',
    value: 'greaterThanInclusive',
  },
  {
    label: '>',
    value: 'greaterThan',
  },
  {
    label: '<=',
    value: 'lessThanInclusive',
  },
  {
    label: '<',
    value: 'lessThan',
  }
]

export function simpleRuleGeneration ({readingName, highThreshold, lowThreshold, alertType, notificationPayload}) {
  const alertTransform = validAlertStragies[alertType]
  if(!alertTransform) return null
  const transformedAlertPayload = alertTransform.transform(notificationPayload)
  if(!transformedAlertPayload) return null
  let rule = {
    conditions: {
      any: [{
        any: [{
          fact: readingName,
          operator: 'greaterThan',
          value: highThreshold,
        }, {
          fact: readingName,
          operator: 'lessThan',
          value: lowThreshold,
        }]
      }]
    },
    event: {
      type: 'SUCCESS',
      params: [transformedAlertPayload]
    }
  }

  return rule
}

export const AlertTypes = ['email', 'pushNotification', 'desktopNotification']

const validAlertStragies = {
  email: {
    id: 'email',
    transform: (value) => {
      if(!value) return null
      return {
        type: 'email',
        data: {
          email: value
        }
      }
    }
  }
}