import { useState, useEffect } from "react";
import {
  Button,
  Heading,
  Pane,
  SideSheet,
  TextareaField,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router";
import { CustomerDevice } from "../models/customer_devices";

const OrganizationSchema = Yup.object().shape({
  name: Yup.string().required("Device name is required!")
});

export default function EditDeviceSideSheet() {
  let { id } = useParams();
  const history = useHistory();
  const [device, setDevice] = useState(null);
  useEffect(() => {
    if (id) getDeviceData(id);
  }, [id]);

  async function getDeviceData(id) {
    let data = await CustomerDevice.find(id);
    setDevice(data.data);
  }
  if (!device) return null;
  return (
    <SideSheet isShown={true} onCloseComplete={() => history.goBack()}>
      <Pane margin={20}>
        <Heading size={800}>Edit: {device.name}</Heading>
        <Pane display="flex" flexDirection="column" marginTop={25}>
          <Formik
            initialValues={{
              name: device.name,
              description: device.description
            }}
            validationSchema={OrganizationSchema}
            onSubmit={async (values) => {
              let dev = (await CustomerDevice.find(id)).data;
              dev.name = values.name || device.name;
              dev.description = values.description || device.description;
              await dev.save();
              if (dev.errors.base) {
                toaster.warning(dev.errors.base.message);
              }

              toaster.success(`${dev.name} has been updated!`);
              history.goBack();
            }}
          >
            {(props) => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  <TextInputField
                    id="name"
                    name="name"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.name}
                    validationMessage={props.errors.name || null}
                    label="Device Name:"
                    defaultValue={props.initialValues.name}
                    type="text"
                  />
                  <TextareaField
                    id="description"
                    name="description"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.description}
                    validationMessage={props.errors.description || null}
                    label="Device Description:"
                    defaultValue={props.initialValues.description}
                    type="text"
                  />
                  <Pane display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Pane>
                </Form>
              );
            }}
          </Formik>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
