import { useRef, useState } from "react";
import "./Landing.css";
import HeroPlant from "../assets/images/hero-image-plant.svg";
import LandingPrimaryButton from "../components/buttons/landingPrimaryLink";
import checkmark from "../assets/images/checkmark.svg";
import {
  Button,
  ArrowRightIcon,
  CrossIcon,
  TickCircleIcon,
} from "evergreen-ui";
import CommonTopBanner from "../shared/CommonTopBanner";
import CommonTopNavigation from "../shared/CommonTopNavigation";
import AppDownloadBtns from "../components/buttons/appDownloadBtns"
import GardenDataAnimation from "../assets/images/track-and-adjust-garden-data-animation.png";
import SensorDataBGImage from "../assets/images/sensor-data-device-for-gardening-colorized.png";
import logoStamp from "../assets/images/100-data-ownership-iot-device.svg";
import UICaptureTemp from "../assets/images/UICapturePlaceholder.png";
import DashboardWithGraph from "../assets/images/DashboardWithGraph.jpg";
import AlertImage from "../assets/images/AlertImage.jpg"
import FlowEditorPreview from "../assets/images/FlowEditorPreview.png"

import { Link } from "react-router-dom";
import scrollToComponent from 'react-scroll-to-component';



export default function LandingPage() {
  const [email, setEmail] = useState('');

  const howRef = useRef(null);
  const featureRef = useRef(null);
  const pricingRef = useRef(null);

  function handleScrollToRef(item) {

    switch (item) {
    case "how":
      scrollToComponent(howRef.current, {
        align: 'top',
        duration: 1500
      });
      break;
    case "feature":
      scrollToComponent(featureRef.current, {
        align: 'top',
        duration: 1500
      });
      break;
    case "pricing":
      scrollToComponent(pricingRef.current, {
        align: 'top',
        duration: 1500
      });
      break;
    default:
      break;
    }
  }

  return (
    <section>
      <CommonTopBanner>
        <Link
          to="/register"
          className="unstyled-link banner-desktop"
          style={{ gridArea: "cta" }}
        >
          Get Started with Juniper Technology <b>for Free</b>
        </Link>
      
        <Link
          to="/login"

          className="unstyled-link"
          style={{ gridArea: "login", textAlign: "right" }}
        >
          <b>Log in</b> to App
        </Link>
      </CommonTopBanner>
      <CommonTopNavigation handleMenuItemClick={handleScrollToRef} />
      <section className="hero-grid">
        <section className="hero-copy">
          <h1 className="hero-header">
            Open Source Compatible IoT Devices and Application for Home &amp;
            Garden
          </h1>
          <p className="hero-description">
            Measure and control sensor data like temperature, pressure, and dew
            point. All in one open source app.
          </p>
          <section className="email-input-wrapper">
            <input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="email"
              className="email-input"
              placeholder="Your email"
            />
            <LandingPrimaryButton text="Start Free" path={email ? `/register?email=${encodeURI(email)}` : "/register" }>
              <ArrowRightIcon
                style={{ verticalAlign: "middle", marginLeft: "0.7rem" }}
                size={16}
              />
            </LandingPrimaryButton>
          </section>
        </section>
        <img
          className="hero-animated-image"
          src={HeroPlant}
          alt="animated plant"
        />
        <section className="app-download">
          <h1 className="hero-header app-download-align">
            We’ve launched the Juniper Garden mobile app!
          </h1>
          <p className="hero-description app-download-align">
            Our app is designed to help you access your most recent 
            readings anytime, anywhere.
          </p>
          <section className="app-download-wrapper">
              <AppDownloadBtns />
          </section>
        </section>
      </section>
      <section className="why-section-grid">
        <h1 className="h2 why-section-title">Why Juniper?</h1>
        <h2 className="h1 why-section-header">
          Gather &amp; Interpret Sensor Data in All Areas of Your Home or Garden.
        </h2>
        <p className="why-section-description">
          Placing your own sensors or Junipers sensors in multiple areas of your home or garden will allow
          maximum control over your plants whether it be in a greenhouse, grow  
          tent, or your home.
        </p>
        <section className="garden-data-animation">
          <img
            className="garden-data-animated-image"
            src={GardenDataAnimation}
            alt="garden data animation"
          />
        </section>
        <section className="pricing-options">
          <h2 className="h1 subsection-header">
            The Juniper App Is Open Source!
          </h2>
          <p>
            Our free version of the Juniper app comes with everything you’ll
            need to track and adjust your home or garden data.
          </p>
          <LandingPrimaryButton text="Pricing Options" onClick={() => handleScrollToRef('pricing')}>
            <ArrowRightIcon
              style={{ verticalAlign: "middle", marginLeft: "0.7rem" }}
              size={16}
            />
          </LandingPrimaryButton>
        </section>
        <section className="data-ingestion">
          <h2 className="h1 subsection-header">
            We have ingested 30+ Million Data-Points into our system allowing users to monitor VPD, Dew Point,
            and More With Precision.
          </h2>
          <p>
            We have developed an open source sensor ingestion platform, that although it's in it's infancy,
            is capable of handling real time data ingestion from any sensor or device in your home, that connects
            to our platform.
          </p>
          <img src={SensorDataBGImage} className="sensor-data-bg" alt="" />
        </section>
        <section className="data-ownership">
          <img
            className="logo-stamp"
            src={logoStamp}
            width={90}
            alt="logostamp"
          />
          {/* {{ display: "inline", whiteSpace: "nowrap" }} */}
          <h1 class="h2 margin-0 inline">100% Data Ownership</h1>
          <img
            src={checkmark}
            width={36}
            alt="checkmark"
            className="sm-hidden"
            style={{ marginLeft: 20, verticalAlign: "text-bottom" }}
          />
          <p>
            Unlike other IoT Software, Juniper awards users the rights to 100%
            of their data. This means unrestricted access, and being able to
            view and manage your data, even removing all of your data by request!
          </p>
        </section>
      </section>
      <section className="how-does-it-work-grid" ref={howRef}>
        <h1 className="h2" style={{ gridArea: "title" }}>
          How Does it Work?
        </h1>
        <section className="steps-container">
          {/* DESKTOP CARD1 WITH FORM */}
          <div className="steps-card">
            <h2 className="h2">Step 1</h2>
            <h3 className="h2 reg">Register for Free!</h3>
            <div className="sm-hidden steps-description">
              <p>
                We pride ourselves on having a robust free tier but we also
                offered paid tiers for power users.
              </p>
            </div>
            <Button is={Link} className="steps-card-button" to="/register">
              Register
              <ArrowRightIcon
                style={{ verticalAlign: "middle", marginLeft: "0.7rem" }}
                size={16}
              />
            </Button>
          </div>

          <div className="steps-card">
            <h2>Step 2</h2>
            <h3 className="h2 reg">Connect your Device(s)</h3>
            <p className="sm-hidden steps-description">
              Once you’ve set up your Juniper IoT devices in your home or garden, we
              need to connect them to our software.
            </p>
            <Button is={Link} className="steps-card-button" to="/support">
              Full Tutorial
              <ArrowRightIcon
                style={{ verticalAlign: "middle", marginLeft: "0.7rem" }}
                size={16}
              />
            </Button>
          </div>
          <div className="steps-card">
            <h2>Step 3 (optional)</h2>
            <h3 className="h2 reg">Purchase IoT Device(s)</h3>
            <div className="sm-hidden steps-description">
              <p>
                Mounting a few of our IoT devices around your home or garden allows us
                to read and process data.
              </p>
            </div>
            <a href="https://shop.junipertechnology.co" className="steps-card-button" >
              Product Page
              <ArrowRightIcon
                style={{ verticalAlign: "middle", marginLeft: "0.7rem" }}
                size={16}
              />
            </a>
          </div>
        </section>
      </section>
      <section className="app-features-grid" ref={featureRef}>
        <h1 className="h2" style={{ gridArea: "header" }}>
          App Features
        </h1>
        <img
          src={DashboardWithGraph}
          class="app-features-screencap"
          style={{ gridArea: "screenshot1" }}
          alt="ui screenshot"
        />
        <section style={{ gridArea: "description1" }}>
          <h2 className="h3">
            {" "}
            Manage Users, Devices, and Areas of Your Garden
          </h2>
          <p>
            The software dashboard comes equipped with different ways of
            managing your home or garden, including unlimited devices to gain data from,
            adding users to work alongside you, and defining specific areas of
            your home or garden so your data is organized.
          </p>
        </section>
        <img
          src={AlertImage}
          class="app-features-screencap"
          style={{ gridArea: "screenshot2" }}
          alt="ui screenshot"
        />
        <section style={{ gridArea: "description2" }}>
          <h2 className="h3">Alerts</h2>
          <p>
            We currently have email alerts (and others on the roadmap), set high and low thresholds and get alerted when your sensors
            react to unusual readings!
          </p>
        </section>
        <img
          src={FlowEditorPreview}
          className="app-features-screencap inactive"
          style={{ gridArea: "screenshot3" }}
          alt="ui screenshot"
        />
        <section style={{ gridArea: "description3" }}>
          <h2 className="h3">Flow Editor (coming soon)</h2>
          <p>
            With our Flow Editor, you'll be able to transform data, make custom calculated datapoints or send
            your data to other external systems!
          </p>
        </section>
      </section>
      <section className="pricing-grid" ref={pricingRef}>
        <section style={{ gridArea: "header", textAlign: "center" }}>
          <h2>Pricing</h2>
          <p>Choose the plan that works for you</p>
        </section>
        <section className="free-features-container">
          <h3>Free Plan</h3>
          <p>The perfect plan for getting started</p>
          <strong style={{ fontSize: "2.0rem" }} className="sm-hidden">
            FREE
          </strong>
          <p className="sm-hidden">100% Free</p>
          <LandingPrimaryButton
            text="Get Started - It's Free!"
            path="/register"
            style={{ width: 200, height: 42 }}
          />
          <section className="free-features">
            <ul>
              <li>
                <img src={checkmark} alt="" />
                Unlimited Devices
              </li>
              <li>
                <img src={checkmark} alt="" />
                Unlimited Users
              </li>
              <li>
                <img src={checkmark} alt="" />
                Unlimited Areas
              </li>
              <li>
                <img src={checkmark} alt="" />
                Unlimited Readings
              </li>
              <li>
                <img src={checkmark} alt="" />
                Unrestricted Access to Your Data
              </li>
              <li>
                <img src={checkmark} alt="" />
                Visual Alerts on Desktop &amp; Mobile
              </li>
            </ul>
          </section>
        </section>
        <section className="paid-features-container">
          <h3>
            Juniper <span style={{ color: "#008F51", margin: 0 }}>+</span>
          </h3>
          <p>When you need a bit more control</p>
          <span className="plus-price" style={{ fontSize: "2.0rem" }}>
            12$<span className="plus-period">/mo</span>
          </span>
          <p>billed monthly</p>
          <LandingPrimaryButton
            text="Get Juniper +"
            path="/register?intent=upgrade"
            // onClick={redirectToCheckout}
            style={{ width: 200, height: 42 }}
          />
          <section className="paid-features">
            <ul>
              <li>
                <img src={checkmark} alt="" />
                Free Plan Features
              </li>
              <li>
                <img src={checkmark} alt="" />
                SMS Alerts
              </li>
              <li>
                <img src={checkmark} alt="" />
                Email Alerts
              </li>
              <li>
                <img src={checkmark} alt="" />
                Web &amp; Mobile Push Notifications
              </li>
              <li>
                <img src={checkmark} alt="" />
                ML Options
              </li>
              <li>
                <img src={checkmark} alt="" />
                Flow Editor
              </li>
              <li>
                <img src={checkmark} alt="" />
                Outbound Webhooks
              </li>
              <li>
                <img src={checkmark} alt="" />
                Outbound Data Sinks
              </li>
            </ul>
          </section>
        </section>
      </section>
      <section className="feature-comparison-container">
        <h3
          style={{
            gridArea: "header",
            padding: "3rem 0",
            background: "#FFCDBB",
            textAlign: "center",
          }}
        >
          Plan Feature Comparison
        </h3>
        <table className="feature-comparison-table" cellSpacing={0}>
          <thead>
            <th>Features</th>
            <th>100% Free</th>
            <th>
              Juniper<span style={{ color: "#008F51", margin: 0 }}>+</span>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Unlimited Devices</td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Unlimited Users</td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Unlimited Areas</td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Unlimited Readings</td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Unrestricted Access to Data</td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Visual Alerts</td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>SMS Alerts (coming soon)</td>
              <td>
                <CrossIcon
                  size={18}
                  style={{ color: "red", verticalAlign: "bottom" }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Email Alerts</td>
              <td>
                <CrossIcon
                  size={16}
                  style={{ color: "red", verticalAlign: "bottom" }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Web &amp; Mobile Push Notifications (coming soon)</td>
              <td>
                <CrossIcon
                  size={16}
                  style={{ color: "red", verticalAlign: "bottom" }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>ML Options (coming soon)</td>
              <td>
                <CrossIcon
                  size={16}
                  style={{ color: "red", verticalAlign: "bottom" }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Flow Editor (coming soon)</td>
              <td>
                <CrossIcon
                  size={16}
                  style={{ color: "red", verticalAlign: "bottom" }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Outbound Webhooks (coming soon)</td>
              <td>
                <CrossIcon
                  size={16}
                  style={{ color: "red", verticalAlign: "bottom" }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Outbound Data Sinks (coming soon)</td>
              <td>
                <CrossIcon
                  size={16}
                  style={{ color: "red", verticalAlign: "bottom" }}
                />
              </td>
              <td>
                <TickCircleIcon
                  size={28}
                  style={{
                    color: "#008F51",
                    verticalAlign: "bottom",
                    overflow: "visible",
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>More Features Coming Soon</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <section className="features-cta">
                  <LandingPrimaryButton
                    text="Get Started Free!"
                    path="/register"
                    style={{ width: 200, height: 42 }}
                  />
                </section>
              </td>
              <td>
                <section className="features-cta pricing">
                  <LandingPrimaryButton
                    text="Get Juniper+"
                    path="/register?intent=upgrade"
                    className="inline-block"
                    style={{ width: 200, height: 42 }}
                  />
                  <h3
                    className="h2 sm-hidden md-hidden inline-block reg margin-0"
                    style={{ paddingLeft: "1rem", color: "#fff" }}
                  >
                    12$
                    <span className="plus-period sm-hidden md-hidden">/mo</span>
                  </h3>
                </section>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </section>
  );
}
