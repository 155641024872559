import { useMemo } from 'react';
import { Alert, Table, IconButton, DeleteIcon, AddIcon, toaster  } from 'evergreen-ui';
import moment from 'moment';
import CurrentUser, { tokenWithPrefix } from "../../../hooks/currentUser";
import baseUrl from '../../../utils/base_url'
import { loadStripe } from '@stripe/stripe-js';
import { Button } from 'evergreen-ui';
let stripePromise = null

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
  }

  return stripePromise
}


export default function Billing() {
  const user = CurrentUser();
  const stripeCharges = useMemo(() => {
    const rawChagesObject = user?.attributes?.stripe_charges;
    if (!rawChagesObject) return [];
    return rawChagesObject[0] && rawChagesObject[0].data
  }, [user])

  const subscriptions = useMemo(() => {
    const rawChagesObject = user?.attributes?.subscriptions;
    if (!rawChagesObject) return [];
    return rawChagesObject[0] && rawChagesObject[0].data
  }, [user])

  if (!user) return null;

  const checkoutOptions = {
    successUrl: `${window.location.origin}/dashboard?stripeId={CHECKOUT_SESSION_ID}`,
    cancelUrl: `${window.location.origin}/dashboard`
  }

  async function getSession() {
    const url = baseUrl + `/rest/v1/stripe/create_session`
    const token = await localStorage.getItem(tokenWithPrefix);
    let res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        ...checkoutOptions
      })
    })

    if (res) {
      return res.json()
    }

    return null
  }

  async function redirectToCheckout() {
    const { session } = await getSession()
    const stripe = await getStripe()
    
    const { error } = await stripe.redirectToCheckout({ sessionId: session.id })

    if (error) {
      console.error(error)
    }
  }



  async function handleCancelSubscription(sub_id) {
    const url = baseUrl+`/rest/v1/stripe/${sub_id}`
    const token = await localStorage.getItem(tokenWithPrefix);
    let res = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    if (res) {
      await res.json()
      toaster.success('Subscription was cancelled successfully!')
    }
  }

  return (
    <section>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2>Billing</h2>
        {user?.attributes?.subscriptions.length == 0 && (
          <Button
            iconAfter={(iconProps) => (
              <AddIcon
                {...iconProps}
                tab-index="-1"
                aria-label={`Purchase Juniper+`}
              />
            )}
            style={{ verticalAlign: 'middle', marginLeft: '1rem' }}
            intent="success"
            onClick={redirectToCheckout}
          >Subscribe To Juniper+</Button>
        )}
      </div>
      <section>
        <h3>Subscriptions: </h3>
        {user?.attributes?.subscriptions.length > 0 && (
          <Alert intent="success">
            You're a Juniper+ member thank you!
          </Alert>
        )}

        <Table marginTop={8}>
          <Table.Head>
            <Table.TextHeaderCell>Start Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
            <Table.TextHeaderCell>Interval</Table.TextHeaderCell>
            <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {subscriptions?.map(subscription => (
              <Table.Row key={subscription.id}>
                <Table.TextCell>
                  {moment(subscription.created*1000).format('ll')}
                  {subscriptions && subscriptions[0].cancel_at && (
                    <p class="red">
                      End Date: {moment(subscription.cancel_at*1000).format('ll')}
                    </p>
                  )}
                </Table.TextCell>
                <Table.TextCell>{(subscription.plan.amount / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}</Table.TextCell>
                <Table.TextCell>{subscription.plan.interval === 'month' && "monthly"}</Table.TextCell>
                <Table.TextCell>
                  <IconButton
                    icon={(iconProps) => (
                      <DeleteIcon
                        {...iconProps}
                        tab-index="-1"
                        aria-label={`Cancel Subscription`}
                      />
                    )}
                    intent="danger"
                    title="Cancel Subscription"
                    onClick={() => handleCancelSubscription(subscription.plan.id)}
                  />
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </section>
      <section>
        <h3>Charges</h3>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>Payment Method</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {stripeCharges?.map(charge => (
              <Table.Row key={charge.id}>
                <Table.TextCell>{moment(charge.created*1000).format('ll')}</Table.TextCell>
                <Table.TextCell>{(charge.amount/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}</Table.TextCell>
                <Table.TextCell>{charge.statement_descriptor}</Table.TextCell>
                <CardDetailsFromStripeCharge stripeCharge={charge} />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </section>
    </section>
  )
}


function CardDetailsFromStripeCharge({stripeCharge}) {
  let chargeType = stripeCharge.payment_method_details.type;
  let chargeAccountNumber = stripeCharge.payment_method_details[chargeType].last4;
  let brand = stripeCharge.payment_method_details[chargeType].brand;

  return <Table.TextCell>{`[${brand}]-${chargeAccountNumber}`}</Table.TextCell>
}