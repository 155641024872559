import { Spinner, StatusIndicator } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import currentSubUrl from "../utils/subscription_url";
import ActionCable from "action-cable-react-jwt";
import { useParams } from 'react-router-dom';
import { tokenWithPrefix } from "../hooks/currentUser";


export default function DeviceStatus({ customer_device_id, classNames }) {
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState('offline')
  const [cable, setCable] = useState(null);
  const params = useParams();
  const [Socket, setSocket] = useState(null);

  useEffect(() => {
    if (!params.id || cable) return;
    let userToken = localStorage.getItem(tokenWithPrefix);
    let App = {};
    App.cable = ActionCable.createConsumer(
      `${currentSubUrl}/cable`,
      userToken
    );

    App.DeviceStateSubscription = App.cable.subscriptions.create(
      { channel: "DeviceStateChannel", id: customer_device_id || params.id },
      {
        connected: () => {
          console.log('Device State connected')
        },
        disconnected: () => {
        },
        received: (data) => {
          let res = JSON.parse(data)
          setLoading(false)
          setStatus(res.status)
        }
      }
    );

    setTimeout(() => {
      setLoading(false)
    }, 10000)

    setSocket(App)
    return () => {
      if(App?.DeviceStateSubscription) {
        App?.cable?.subscriptions?.remove(App.DeviceStateSubscription)
      }
      App.cable.disconnect();
    }
  }, []);
  
  if(loading) {
    return (
      <p className={classNames}>
        <Spinner size={8} />
      </p>
    )
  }

  return (
    <p className={classNames}>
      {status === 'online' && <StatusIndicator color="success" style={{ paddingRight: "0.5rem" }}>{status}</StatusIndicator>}
      {status === 'offline' && <StatusIndicator color="danger" style={{ paddingRight: "0.5rem" }}>{status}</StatusIndicator>}
    </p>
  )
}