import { useEffect, useState, useContext, useMemo, useRef } from "react";
import { useParams, useHistory, useLocation, Route } from "react-router";
import { Sensor } from "../../../models/sensor";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { orderBy } from "lodash";
import { CustomerDeviceCard } from "../Device";
import { RawSensor } from "../../../models/raw_sensor";
import { Button, Label, Select, Spinner } from "evergreen-ui";
import { CustomerDevice } from "../../../models/customer_devices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
require("highcharts/modules/export-data")(Highcharts);

const chartInitialOptions = {
  chart: {
    zoomType: "x",
  },
  yAxis: [
    {
      height: "100%",
    },
  ],
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      millisecond: "%H:%M:%S.%L",
    },
  },
  rangeSelector: {
    allButtonsEnabled: true,
    buttons: [
      {
        type: "second",
        count: 1,
        text: "1s",
      },
      {
        type: "minute",
        count: 1,
        text: "1m",
      },
      {
        type: "hour",
        count: 1,
        text: "1h",
      },
      {
        type: "all",
        count: "all",
        text: "All",
      },
    ],
  },
  exporting: {
    csv: {
      dateFormat: "%Y-%m-%d %H:%M:%S:%L",
    },
  },
};

const DEFAULT_DATA_SIZE = 40000;

export default function DeviceReports() {
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [dateRange, setDateRange] = useState({
    endDate: moment().endOf("day").toDate(),
    startDate: moment().subtract(1, "days").toDate(),
  });
  const [chartOptions, setChartOptions] = useState(chartInitialOptions);
  const chartRef = useRef();

  useEffect(() => {
    if (!params.id) return;
    loadInitialSensorReadingsAndDeviceTypes();
  }, []);

  async function loadInitialSensorReadingsAndDeviceTypes() {
    let { data: device } = await CustomerDevice.includes([
      "alerts",
      "sensor_readings",
      "organization",
      "categories",
    ])
      .selectExtra(["sensorTypeOptions"])
      .find(params.id);

    if (!device || !device.sensorTypeOptions) {
      setLoading(false);
      return;
    }
    const endDate = moment(dateRange.endDate).endOf("day");
    const startDate = moment(dateRange.startDate);
    const firstSelectedSensor = device.sensorTypeOptions[0];

    let { data } = await RawSensor.where({
      customer_device_id: params.id,
      name: firstSelectedSensor,
      timestamp: { gte: startDate.toString(), lte: endDate.toString() },
    })
      .page(0)
      .per(DEFAULT_DATA_SIZE)
      .all();

    setSelectedSensor(firstSelectedSensor);
    setDeviceTypes(device.sensorTypeOptions);
    setChartSeriesData(data.reverse());
    setLoading(false);
  }

  async function getMoreSensorReadings({ startDate, endDate, sensorName }) {
    let chartObj = chartRef.current.chart;

    chartObj.showLoading();
    let SensorModelToQuery = RawSensor;
    if (moment(endDate).diff(moment(startDate), "days") > 2) {
      let res = window.confirm(
        `Are you sure you want to query a max of ${
          parseInt(moment(endDate).diff(moment(startDate), "days")) *
          24 *
          60 *
          60
        } data points? This can result is a slow response time.`
      );
      if (!res) {
        chartObj.hideLoading();
        return;
      }
    }

    let { data } = await SensorModelToQuery.where({
      customer_device_id: params.id,
      name: sensorName,
      timestamp: { gte: startDate.toString(), lte: endDate.toString() },
    })
      .page(0)
      .per(DEFAULT_DATA_SIZE)
      .all();
    setChartSeriesData(data.reverse());
    chartObj.hideLoading();
  }

  function setChartSeriesData(readings) {
    let data = readings.map((reading) => [
      moment(reading.timestamp).unix() * 1000,
      parseFloat(reading.value),
    ]);
    if (!readings[0]) return;
    setChartOptions({
      ...chartInitialOptions,
      series: [{ name: readings[0].name, data: data }],
    });
  }

  async function handleSelectSensor(sensor) {
    let chartObj = chartRef.current.chart;

    chartObj.showLoading();
    await Promise.all([
      setSelectedSensor(sensor),
      getMoreSensorReadings({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        sensorName: selectedSensor,
      }),
    ]);
    chartObj.hideLoading();
  }

  function handleDateRangeChange(date) {
    const [startDate, endDate] = date;
    let chartObj = chartRef.current.chart;

    chartObj.showLoading();
    setDateRange({ startDate, endDate });
    if (startDate && endDate)
      getMoreSensorReadings({ startDate, endDate, sensorName: selectedSensor });

    chartObj.hideLoading();
  }

  function handleCSVDownload() {
    let csvContent = chartRef.current.chart.getCSV();
    const filename = `${selectedSensor}_${moment(dateRange.startDate).format(
      "YYYY-MM-DD"
    )}_${moment(dateRange.endDate).format("YYYY-MM-DD")}.csv`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <>
      {deviceTypes?.length > 0 && (
        <div style={{ paddingTop: 20 }}>
          <Label>Sensor Type Selection</Label>
          <Select
            onChange={(e) => handleSelectSensor(e.target.value)}
            style={{ marginLeft: 20 }}
          >
            {deviceTypes.map((type, idx) => (
              <option value={type} key={`${type}- ${idx}`}>
                {type}
              </option>
            ))}
          </Select>
        </div>
      )}
      <div>
        <Label>Date Range Selection</Label>
        <DatePicker
          selected={dateRange.startDate}
          onChange={handleDateRangeChange}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          selectsRange
        />
      </div>
      {loading && <Spinner size={32} />}
      {!loading && (
        <div style={{ paddingTop: 20 }}>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={chartOptions}
            ref={chartRef}
          />
          <Button style={{ marginTop: 20 }} onClick={handleCSVDownload}>
            Export Data to CSV
          </Button>
        </div>
      )}
    </>
  );
}
