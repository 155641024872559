import { Checkbox, Pane } from "evergreen-ui";
import CommonTopPageHeader from "../shared/CommonTopPageHeader";
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GlobalContext } from '../store/globalStore';

const NotificationPage = observer(() => {
  const globalStore = useContext(GlobalContext);
  useEffect(() => {
    globalStore.getAlertLogs();
    globalStore.subscribe()
    return () => {
      globalStore.unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function toggleReadStatus(item) {
    globalStore.Socket.device_alert_log.send({ hasBeenRead: !item.hasBeenRead, id: item.id })
    await globalStore.getAlertLogs();
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      overflowY="scroll"
      minHeight="100vh"
      flex="1"
    >
      <CommonTopPageHeader>
        <h1 style={{ margin: 0, verticalAlign: "middle" }}>Notifications</h1>
      </CommonTopPageHeader>
      <div style={{ overflow: 'scroll' }}>
        {globalStore.alerts.length > 0 && globalStore.alerts.map(alert => <Checkbox key={alert.id} disabled={alert.hasBeenRead} indeterminate={alert.hasBeenRead} label={alert.message} onChange={() => toggleReadStatus(alert)} checked={alert.hasBeenRead}/>)}
      </div>
    </Pane>
  );
})


export default NotificationPage