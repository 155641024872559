import { useEffect, useMemo, useContext } from "react";
import {
  Button,
  Heading,
  Pane,
  Select,
  SelectField,
  SideSheet,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router";
import { DeviceContext } from "../pages/Devices/DeviceStore";
import CurrentUser from "../hooks/currentUser";
import { AlertTypes, simpleRuleGeneration } from "../utils/jsonRulesUtils";
import AlertTypeInput from "../components/alert_type_input/alertTypeInput";
import { Alert } from "../models/alerts";
import { CustomerDevice } from "../models/customer_devices";


const AlertSchema = Yup.object().shape({
  alertName: Yup.string().required("Alert Name is required!"),
  readingName: Yup.string().required("Reading Name is required!"),
  highThreshold: Yup.number().required("High Threshold is required!")
    .when('lowThreshold', (lowThreshold) => {
      if (lowThreshold) {
        return Yup.number()
          .min(lowThreshold, 'High threshold must be greater than Low Threshold')
          .typeError('High Threshold is required!')
      }
    }),
  lowThreshold: Yup.number().required("Low Threshold is required!"),
  alertType: Yup.string().oneOf(AlertTypes).required("Alerts have to have an outcome!"),
  notificationPayload: Yup.mixed().required("Notification Payload is required!")
})

export default function CreateCustomerDeviceAlert() {
  const mobxDevice = useContext(DeviceContext)
  const user = CurrentUser()
  
  let { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!id) return
    if(!mobxDevice.device.data?.id) mobxDevice.getDevice(id)
  }, [])

  const sensorNameOptions = useMemo(() => {
    const optionsSet = new Set();
    mobxDevice.device?.data?.sensorReadings.forEach((sensor) => {
      optionsSet.add(sensor.name);
    })
    
    return Array.from(optionsSet)
  }, [mobxDevice.device?.data?.sensorReadings]);

  async function handleSubmit(values) {
    const rule = simpleRuleGeneration(values);
    const { data: cd } = await CustomerDevice.find(id);
    const alert = new Alert({ jsonRule: rule, name: values.alertName, status: 'active' });

    cd.alerts.push(alert);

    await cd.save({ with: ['alerts'] });
    if (cd.errors.base) {
      toaster.warning(cd.errors.base.message);
    }

    toaster.success(`Alert ${values.alertName} has been created!`);
    history.goBack();
  }
  if(!user  || mobxDevice.loading) return null
  return (
    <SideSheet isShown={true} onCloseComplete={() => history.goBack()}>
      <Pane margin={20}>
        <Heading size={800}>Create New Alert for: {mobxDevice.device.data.name} </Heading>
        <Pane display="flex" flexDirection="column" marginTop={25}>
          <Formik
            enableReinitialize
            initialValues={{
              alertName: "",
              readingName: sensorNameOptions[0],
              highThreshold: "",
              lowThreshold: "",
              alertType: "email",
              notificationPayload: user.attributes.email || ""
            }}
            validationSchema={AlertSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  <TextInputField
                    label="Alert Name"
                    validationMessage={props.errors.alertName}
                    description="What is the name of this alert?"
                    onChange={event => props.setFieldValue('alertName', event.target.value)}
                  />
                  <SelectField label="Reading Name" onChange={event => props.setFieldValue('readingName', event.target.value)} defaultValue={props.initialValues.readingName || ''}>
                    {sensorNameOptions.map((sensorName) => (
                      <option key={sensorName} value={sensorName}>
                        {sensorName}
                      </option>
                    ))}
                  </SelectField>
                  <TextInputField
                    label="High Threshold"
                    type="number"
                    validationMessage={props.errors.highThreshold}
                    description="This is the highest number, exclusive, that will trigger an alert."
                    onChange={event => props.setFieldValue('highThreshold', event.target.value)}
                  />
                  <TextInputField
                    label="Low Threshold"
                    type="number"
                    validationMessage={props.errors.lowThreshold}
                    description="This is the lowest number, exclusive, that will trigger an alert."
                    onChange={event => props.setFieldValue('lowThreshold', event.target.value)}
                  />
                  <SelectField label="Alert Type" onChange={event => props.setFieldValue('alertType', event.target.value)} defaultValue={props.initialValues.alertType}>
                    {AlertTypes.map((alertType) => (
                      <option key={alertType} value={alertType}>
                        {alertType}
                      </option>
                    ))}
                  </SelectField>
                  {props.values.alertType && (
                    <AlertTypeInput
                      {...props}
                      alertType={props.values.alertType}
                      defaultValue={props.initialValues.notificationPayload}
                      validationMessage={props.errors.notificationPayload}
                    />
                  )}
                  <Pane display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Pane>
                </Form>
              )
            }}
          </Formik>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
