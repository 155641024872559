import ApplicationRecord from "./application";
import { attr, belongsTo } from "spraypaint/dist/spraypaint";

const OrganizationCredentials = ApplicationRecord.extend({
  static: {
    jsonapiType: "organization_credentials",
  },
  attrs: {
    grantType: attr(),
    grantScope: attr(),
    metadata: attr(),
    key: attr(),
    createdAt: attr({ persist: false }),
    updatedAt: attr({ persist: false }),
    organization: belongsTo()
  },
});

export { OrganizationCredentials };
