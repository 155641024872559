import { PrismCode } from "react-prism"

const EsphomeText = ({id}) => (
  <>
    <p>For Esphome</p>
    <PrismCode component="pre" language="yaml" className="language-yaml">
      {`
              - http_request.send:
                  method: POST
                  url: https://ingestion.junipertechnology.co/sensor-ingest
                  verify_ssl: false
                  headers:
                    Content-Type: application/json
                  json: |-
                    root["id"] = "${id}";
                    root["timestamp"] = id(sntp_time).utcnow().timestamp;
                    JsonArray &readings = root.createNestedArray("readings");
                    JsonObject &reading = readings.createNestedObject();
                    reading["name"] = "the name of measurement";
                    reading["unit"] = "the unit of the measurement";
                    reading["value"] = "the float value of the measurement";
                    readings.add(reading);
            `}
    </PrismCode>
  </>
)

export default EsphomeText