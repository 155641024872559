import {
  Button,
  IconButton,
  majorScale,
  Pane,
  PlusIcon,
  Table,
  EyeOpenIcon,
  TrashIcon,
  toaster,
  Badge,
} from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { useHistory, Switch, Route } from 'react-router'
import { Category } from '../../models/category'
import Area from './Area'
import CommonTopPageHeader from '../../shared/CommonTopPageHeader'
import GenericInfoPanel from '../../components/info-panels/generic-info-panel'
import NewAreaSideSheet from './NewAreaSideSheet'
import { useLocation } from 'react-router-dom'

export default function AreasPage() {
  return (
    <Pane display="flex" flex="1" flexDirection="column">
      <Switch>
        <Route exact path="/dashboard/areas" component={AreasList} />
        <Route exact path="/dashboard/areas/new" component={AreasList} />
        <Route path="/dashboard/areas/:id" component={Area} />
      </Switch>
    </Pane>
  )
}

function AreasList() {
  const [areas, setAreas] = useState([])
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    getAreaData()
  }, [location.pathname])

  async function getAreaData() {
    let { data } = await Category.areas().includes('customer_devices').all()
    setAreas(data || [])
  }

  function goToAddArea() {
    history.push('/dashboard/areas/new')
  }

  return (
    <>
      <Pane
        display="flex"
        flexDirection="column"
        overflowY="scroll"
        minHeight="100vh"
        flex="1"
      >
        <CommonTopPageHeader>
          <h1 style={{ margin: 0, verticalAlign: 'middle' }}>Areas</h1>
          <div>
            <Button
              iconBefore={PlusIcon}
              intent="none"
              onClick={goToAddArea}
              marginLeft={10}
              height={42}
            >
              Add New Area
            </Button>
          </div>
        </CommonTopPageHeader>
        <GenericInfoPanel label={'Areas'} count={areas.length} />
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body flex="1">
            {areas.map((area) => (
              <AreaRow key={area.id} area={area} handleRefresh={getAreaData} />
            ))}
          </Table.Body>
        </Table>
      </Pane>
      <Route exact path="/dashboard/areas/new" component={NewAreaSideSheet} />
    </>
  )
}

function AreaRow({ area, handleRefresh }) {
  const history = useHistory()

  function goToArea() {
    history.push(`/dashboard/areas/${area.id}/show`)
  }

  async function archiveArea() {
    if (window.confirm(`Are you sure you want to archive ${area.name}?`)) {
      let existingCat = (await Category.find(area.id)).data
      if (!existingCat) return toaster.warning('Area not found')

      existingCat.status = 'archived'
      await existingCat.save()
      if (existingCat.errors.base) {
        toaster.warning(existingCat.errors.base.message)
      }

      toaster.success(`${existingCat.name} has been archived!`)
      handleRefresh()
    }
  }

  return (
    <Table.Row key={area.id}>
      <Table.TextCell>
        {area.status !== 'active' && <Badge color="red">Archived</Badge>}{' '}
        {area.name}
      </Table.TextCell>
      <Table.TextCell>{area.description}</Table.TextCell>
      <Table.TextCell>
        <IconButton
          icon={(iconProps) => (
            <EyeOpenIcon
              {...iconProps}
              tab-index="-1"
              aria-label={`Show Area ${area.name}`}
            />
          )}
          marginRight={majorScale(2)}
          intent="none"
          onClick={goToArea}
        />
        {area.status === 'active' && (
          <IconButton
            icon={(iconProps) => (
              <TrashIcon
                {...iconProps}
                tab-index="-1"
                aria-label={`Delete Area ${area.name}`}
              />
            )}
            marginRight={majorScale(2)}
            intent="danger"
            onClick={archiveArea}
          />
        )}
      </Table.TextCell>
    </Table.Row>
  )
}
