import ApplicationRecord from "./application";
import { attr, hasMany } from "spraypaint/dist/spraypaint";

const Category = ApplicationRecord.extend({
  static: {
    jsonapiType: "categories",
    areas: function () {
      return this.where({ topLevel: true }).stats({ total: 'count'});
    },
  },
  attrs: {
    id: attr(),
    name: attr(),
    topLevel: attr(),
    status: attr(),
    customerDevices: hasMany(),
    organization: hasMany(),
  },
});

export { Category };
