const AreasDuotoneIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 17.5C22 19.9853 17.5228 22 12 22C6.47715 22 2 19.9853 2 17.5C2 16.2412 3.14864 15.1031 5 14.2864C6.80375 13.4906 9.27455 13 12 13C14.7255 13 17.1962 13.4906 19 14.2864C20.8514 15.1031 22 16.2412 22 17.5Z"
      fill="#C8E2D3"
    />
    <path
      d="M5 14.2871C3.14864 15.1341 2 16.3145 2 17.6201C2 20.1976 6.47715 22.2871 12 22.2871C17.5228 22.2871 22 20.1976 22 17.6201C22 16.3145 20.8514 15.1341 19 14.2871"
      stroke="#525C7A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.25 8C18.25 9.85139 17.5436 12.4771 16.3444 14.6358C15.7478 15.7096 15.05 16.6296 14.2949 17.2728C13.5416 17.9145 12.7702 18.25 12 18.25C11.2298 18.25 10.4584 17.9145 9.7051 17.2728C8.95 16.6296 8.2522 15.7096 7.65562 14.6358C6.45636 12.4771 5.75 9.85139 5.75 8C5.75 4.41421 8.41421 1.75 12 1.75C15.5858 1.75 18.25 4.41421 18.25 8ZM12 10.75C13.5188 10.75 14.75 9.51878 14.75 8C14.75 6.48122 13.5188 5.25 12 5.25C10.4812 5.25 9.25 6.48122 9.25 8C9.25 9.51878 10.4812 10.75 12 10.75Z"
      fill="#C8E2D3"
      stroke="#525C7A"
      strokeWidth="1.5"
    />
  </svg>
);

export default AreasDuotoneIcon;
