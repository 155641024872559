import { Button, MenuIcon, IconButton } from "evergreen-ui";
import { useState } from "react";
import logoLg from "../assets/images/Juniper_Garden_Hrz_2Color.png";
import logoSm from "../assets/images/Juniper_Icon_Green.svg";
import styles from "./CommonTopNavigation.module.css";
import { Link } from 'react-router-dom';

// const FancyLink = React.forwardRef(({ navigate, ...props }, ref) => {
//   return (
//     <Button
//     size="medium"
//     className={`${styles.button_outline} ${styles.nav_desktop}`}
//     {...props}
//     ref={ref}
    
//   >
//     Login
//   </Button>
//     <a ref={ref} {...props} onClick={handleClick} >💅 {props.children}</a>
//   )
// })

export default function CommonTopNavigation(props) {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const toggleMenu = () => {
    setMenuIsVisible(!menuIsVisible);
  };

  function handleMenuItemClick (value) {
    props.handleMenuItemClick(value)
  }

  return (
    <section className={styles.landing_top_nav}>
      <img
        src={logoLg}
        width={200}
        className="sm-hidden md-hidden"
        alt="juniper logo"
        style={{ gridArea: "logo" }}
      />
      <nav
        className={`${styles.landing_links} ${styles.nav_desktop}`}
        style={{ gridArea: "nav" }}
      >
        <img
          src={logoSm}
          height={42}
          className="lg-hidden"
          alt="juniper logo"
        />
        <a href="/">Home</a>
        <a href="#" onClick={() => handleMenuItemClick('how')}>How Does It Work?</a>
        <a href="#" onClick={() => handleMenuItemClick('feature')}>Features</a>
        <a href="#" onClick={() => handleMenuItemClick('pricing')}>Pricing</a>
        <a href="https://shop.junipertechnology.co/" target="_blank" rel="noopener noreferrer">Shop</a>
        <Link
          to="/login"
          className={`button ${styles.button_outline} ${styles.nav_desktop}`}
        >Login</Link>
       
        <Button
          size="medium"
          is={Link}
          to={"/register"}
          className={styles.button}
          style={{ gridArea: "cta" }}
        >
          Get Started
        </Button>
      </nav>

      <img
        src={logoSm}
        height={42}
        className="md-hidden lg-hidden"
        alt="juniper logo"
      />

      <IconButton
        icon={MenuIcon}
        className={styles.nav_mobile}
        style={{
          verticalAlign: "center",
          background: "transparent",
          gridArea: "menu",
          justifySelf: "end",
          alignSelf: "center",
        }}
        size={"medium"}
        onClick={toggleMenu}
      />
      {menuIsVisible && (
        <nav className={`${styles.landing_links} ${styles.nav_mobile}`}>
          <a href="/">Home</a>
          <a href="#" onClick={() => handleMenuItemClick('how')}>How Does It Work?</a>
          <a href="#" onClick={() => handleMenuItemClick('feature')}>Features</a>
          <a href="#" onClick={() => handleMenuItemClick('pricing')}>Pricing</a>
          <a href="https://shop.junipertechnology.co/" target="_blank" rel="noopener noreferrer">Shop</a>
          <a href="/">FAQ</a>
          <a href="mailto:placeholderemail@placeholderdomain.com">Contact</a>
        </nav>
      )}
    </section>
  );
}
