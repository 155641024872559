
import { useEffect } from 'react';
import { useParams,useHistory, useLocation, Route  } from 'react-router';
import GenericInfoPanel from '../../../components/info-panels/generic-info-panel';
import { CustomerDevice } from '../../../models/customer_devices';

import { Pane, Spinner, Table, PlusIcon, Button, TrashIcon, IconButton, majorScale } from 'evergreen-ui';
import CreateCustomerDeviceAlert from '../../../shared/CreateCustomerDeviceAlert';
import { useContext } from 'react';
import { DeviceContext } from '../DeviceStore';
import { Alert } from '../../../models/alerts';

export default function DeviceAlerts() {
  const mobxDevice = useContext(DeviceContext)
  const params = useParams()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (!params.id) return
    if(!mobxDevice.device.data?.id) mobxDevice.getDevice(params.id)
  }, [])

  useEffect(() => {
    if (!params.id) return
    mobxDevice.refreshDevice()
  }, [params, location.pathname])  

  async function handleArchiveAlert(inboundAlert) {
    let {data: alert} = await Alert.find(inboundAlert.id)
    // alert window for confirmation
    let confirmed = window.confirm(`Are you sure you want to archive ${alert.name}?`)
    if(!confirmed) return

    alert.status = 'archived'
    await alert.save()
    mobxDevice.refreshDevice()
  }

  function goToAddAlert() {
    history.push('/dashboard/devices/' + params.id + '/alerts/new')
  }

  if (mobxDevice.loading) return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center" 
      overflowY="scroll"
      height="100vh"
      className="hidden-scrollbar"
    >
      <Spinner size={32} />
    </Pane>
  );
  
  return(
    <>
      <GenericInfoPanel label={"Alerts"} count={mobxDevice.device.data?.alerts.length} />
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          <Table.TextHeaderCell>
            Actions
            <Button
              iconBefore={PlusIcon}
              appearance="primary"
              onClick={goToAddAlert}
              marginLeft={10}
              height={32}
              alignSelf="flex-end"
            >
              Add New Alert
            </Button>
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body height={240} flex="1">
          {mobxDevice.device.data?.alerts.map((alert) => (
            <Table.Row key={alert.id}>
              <Table.TextCell>{alert.name}</Table.TextCell>
              <Table.TextCell>{alert.status}</Table.TextCell>
              <Table.TextCell>
                {alert.status === 'active' && (
                  <IconButton
                    icon={(iconProps) => (
                      <TrashIcon
                        {...iconProps}
                        tab-index="-1"
                        aria-label={`Delete Alert`}
                      />
                    )}
                    marginRight={majorScale(2)}
                    intent="danger"
                    onClick={() => handleArchiveAlert(alert)}
                  />
                )}
              </Table.TextCell>
              <Table.TextCell />
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Route
        path="/dashboard/devices/:id/alerts/new"
        component={CreateCustomerDeviceAlert}
      />
    </>
  )
}