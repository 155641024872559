import { useState } from "react";
import { Select } from "evergreen-ui";
import "./no_sensors.css";
import ModdableSdkText from "../provisioning_docs/moddableSdk";
import EsphomeText from "../provisioning_docs/esphome";
import HTTPText from "../provisioning_docs/http";
import classNames from "classnames";

const OptionComponentMap = {
  nil: <> </>,
  moddable: ModdableSdkText,
  esphome: EsphomeText,
  http: HTTPText,
};

export default function NoSensors({ id }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const SelectedProvisioningDoc = OptionComponentMap[selectedOption];
  const classes = classNames({
    "device-card-onboard": true,
    "device-card-expanded": selectedOption,
  });

  function handleOptionSelect(event) {
    setSelectedOption(event.target.value === "nil" ? null : event.target.value);
  }

  return (
    <section className={classes}>
      <h2>Getting Started</h2>
      <Select onChange={handleOptionSelect} className="code-completion-select">
        <option value="nil"></option>
        <option value="moddable">Moddable Sdk</option>
        <option value="esphome">Esphome</option>
        <option value="http">Http</option>
      </Select>
      {selectedOption && <SelectedProvisioningDoc id={id} />}
    </section>
  );
}
