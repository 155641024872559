import { Button, Pane, PlusIcon, Table } from "evergreen-ui";
import { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";
import { Organization } from "../models/organization";
import CommonTopPageHeader from "../shared/CommonTopPageHeader";
import CreateOrganizationSideSheet from "../shared/CreateOrganizationSidesheet";
import EditOrganizationSideSheet from "../shared/EditOrganizationSidesheet";
import GenericInfoPanel from '../components/info-panels/generic-info-panel';

export default function OrganizationsPage() {
  const history = useHistory();
  const location = useLocation();
  const [organizations, setOrganizations] = useState([]);
  const [orgCount, setOrgCount] = useState(0);
  useEffect(() => {
    getOrgData();
  }, [location.pathname]);

  async function getOrgData() {
    let data = await Organization.stats({ total: "count" }).per(1000).all();
    setOrgCount(data.meta.stats.total.count);
    setOrganizations(data.data);
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      overflowY="scroll"
      minHeight="100vh"
      flex="1"
    >
      <CommonTopPageHeader>
        <h1 style={{ margin: 0, verticalAlign: "middle" }}>Organizations Page</h1>
        <div>
          <Button
            iconBefore={PlusIcon}
            intent="none"
            onClick={() => history.push("/dashboard/organizations/create")}
            marginLeft={10}
            height={42}
          >
          Create New Organization
          </Button>
        </div>
      </CommonTopPageHeader>
      <GenericInfoPanel label={"Organizations"} count={orgCount} />
      <Pane  marginTop={15} display="flex" flex={1} flexDirection="column">
        <Pane display="flex" flexDirection="column">
          {organizations.length > 0 && (
            <OrganizationsList organizations={organizations} />
          )}
        </Pane>
        <Route path="/dashboard/organizations/create">
          <CreateOrganizationSideSheet />
        </Route>
        <Route path="/dashboard/organizations/edit/:id">
          <EditOrganizationSideSheet />
        </Route>
      </Pane>
    </Pane>
  );
}

function OrganizationsList({ organizations }) {
  return (
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Org Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Org Address</Table.TextHeaderCell>
        <Table.TextHeaderCell>Org Phone Number</Table.TextHeaderCell>
        <Table.TextHeaderCell>Org Email Address</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body flex="1">{organizations.map(OrganizationRow)}</Table.Body>
    </Table>
  );
}

function OrganizationRow(organization) {
  const history = useHistory();
  return (
    <Table.Row
      key={organization.id}
      isSelectable
      onSelect={() =>
        history.push(`/dashboard/organizations/edit/${organization.id}`)
      }
    >
      <Table.TextCell>{organization.name}</Table.TextCell>
      <Table.TextCell>{organization.address}</Table.TextCell>
      <Table.TextCell isNumber>{organization.primaryPhone}</Table.TextCell>
      <Table.TextCell>{organization.primaryEmail}</Table.TextCell>
    </Table.Row>
  );
}
