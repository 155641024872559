import { useState, useEffect } from "react";
import {
  Button,
  Heading,
  Pane,
  SideSheet,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Organization } from "../../models/organization";
import { Category } from "../../models/category";

const AreaSchema = Yup.object().shape({
  name: Yup.string().required("Area name is required!")
});

export default function NewAreaSideSheet() {
  const history = useHistory();
  const [org, setOrg] = useState([]);
  useEffect(() => {
    fetchOrganizations();
  },[])

  async function fetchOrganizations() {
    let { data: orgData} = await Organization.first();
    setOrg(orgData);
  }
  return (
    <SideSheet isShown={true} onCloseComplete={() => history.goBack()}>
      <Pane margin={20}>
        <Heading size={800}>Create a new Area</Heading>
        <Pane display="flex" flexDirection="column" marginTop={25}>
          <Formik
            initialValues={{
              name: ''
            }}
            validationSchema={AreaSchema}
            onSubmit={async (values) => {
              let newCat = new Category({...values, topLevel: true })
              newCat.organization = org
              await newCat.save({ with: ['organization'] });
              if (newCat.errors.base) {
                toaster.warning(newCat.errors.base.message);
              }
              
              toaster.success(`${newCat.name} has been created!`);
              history.goBack();
            }}
          >
            {(props) => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  <TextInputField
                    id="name"
                    name="name"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.name}
                    validationMessage={props.errors.name || null}
                    label="Area Name:"
                    placeholder={'Area Name'}
                    type="text"
                  />
                  <Pane display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Pane>
                </Form>
              );
            }}
          </Formik>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
