import "./Support.css";
import AppDownloadBtns from "../components/buttons/appDownloadBtns";
import logo from "../assets/images/Juniper_Garden_Hrz_2Color.png";
import download from "../assets/images/download.png";
import qrCode from "../assets/images/dl-qr-code.png";
import { JUNIPER_LOGIN_URL, JUNIPER_SUPPORT_EMAIL } from "../utils/constants";



export default function SupportPage() {
  const isMobile = window.screen.width < 729
  return (
    <div>
      <div className="support-header">
        <div className="support-left-container">
          <h1 className="support-heading">Setup and support</h1>
          <div className="support-description">
            <div>Need additional support?</div>
            <div>Email us at: <a className="download-text" href={`mailto:${JUNIPER_SUPPORT_EMAIL}`}>{JUNIPER_SUPPORT_EMAIL}</a></div>
          </div>
          <h2>Download the Juniper Garden mobile app</h2>
          <AppDownloadBtns />
        </div>
        {!isMobile && 
          <img
            className="support-image"
            src={logo}
            alt="juniper logo"
          />
        }
      </div>
      <div className="support-container">
        <h1 className="support-heading">Twig Setup Instructions</h1>
        {!isMobile && 
          <a className="download-link" target="_blank" href="https://docs.google.com/document/d/1xPo2VCGXc8B60PljY_9T7UOUKOvtguLmnCarESp2tEc/edit" rel="noreferrer">
            <div className="download-pdf">
              <img src={download} alt="download icon"/>
              <span className="download-text">Setup Instructions</span>
            </div>
          </a>
        }
        
        <h2>In the bag</h2>
        <ol>
          <li>Twig temperature, humidity, and pressure sensor with LED</li>
          <li>Power adaptor</li>
          <li>USB-C cable</li>
        </ol>

        <h2>Before you begin:</h2>
        <ol>
          <li>Check the contents of the box.</li>
          <li>Make sure your home wifi is working and connected to the internet.</li>
          <li>Download the Juniper Technology mobile application for your mobile device using the QR code below. You can also access our web app at <a className="download-text" href={JUNIPER_LOGIN_URL}>{JUNIPER_LOGIN_URL}</a></li>
          <img
            src={qrCode}
            alt="download app qr code"
          />
          <li>Find the best spot for your sensor. With the provided cable, it will need to be within 3 feet of a power source, but you may use a cable up to 15’ in length.</li>
        </ol>

        <h2>Sensor setup</h2>
        <ol>
          <li>Connect your new Twig sensor to the provided USB cable. If you have more than one Twig, do this setup one at a time. </li>
          <li>Attach the cable to the provided power adaptor and plug it in. You should see a red light indicating that the device is receiving power.</li>
          <li>Open the Juniper Technology application on your mobile device.</li>
          <li>Sign up by entering your email and selecting a password.</li>
          <li>Tap on the “Areas” tab in the navigation at the bottom of the screen.</li>
          <li>Tap the “+” in the top right to add a new area. Give it a name that represents the room or area that this device will live, and save (“Add Area”). If you have more devices for other areas, you can add them here now.</li>
          <li>Tap on the “Devices” tab in the navigation at the bottom of the screen.</li>
          <li>Tap the “+” button in the top right of the screen to begin adding a device.</li>
          <li>Select the area for this device and tap “Next Step.”</li>
          <li>Enter your network’s WIFI credentials: WIFI SSID (network name) and password. Tap “Next Step.”</li>
          <li>Once the list populates, Select the sensor from the list of available Bluetooth devices on your network. There should be a serial number that matches the number on the sensor. Tap “Next Step.” This will pass your network’s credentials to the sensor device.</li>
          <li>The next screen will show you the progress while claiming the device. Please wait.</li>
          <li>When the device is ready, you will see a Success screen, and the Twig LED will now be green, indicating that it is now connected and sending data.</li>
          <li>Tap “Go To Devices” to see your device list or add another Twig.</li>
          <li>Head to the Home screen of the application to see your data. Data readings should start populating within one to five minutes of adding a new sensor.</li>
          <li>Readings will update every 60 seconds or less.</li>
        </ol>

        <h2>Tips</h2>
        <ol>
          <li>The Twig sensor must remain plugged in.</li>
          <li>You will know if it is properly connected to your network and sending data if the LED light is a steady green (not flashing).</li>
          <li>Scan the QR code on the Twig to be taken to the readings for that sensor in the web application. Here you will find historical readings in addition to the latest readings. You can also log in here: </li>
          <li>The colors of the LED on the sensor indicate:
            <ol type="a">
              <li>Red (steady) = Power on, no connection (not sending data)</li>
              <li>Green (steady) = Connected (sending data)</li>
              <li>Blue (blinking) = Power, connected but with error (not sending data)</li>
            </ol>
          </li>
          <li>The formats of the readings are:
            <ol type="a">
              <li>Temperature in degrees F</li>
              <li>Relative humidity in %</li>
              <li>Pressure in kPa</li>
            </ol>
          </li>
        </ol>

        <h2>Troubleshooting</h2>
        <ol>        
          <li>If there is no light on the sensor, check the power, cable connections and adaptor.</li>
          <li>If during setup the sensor is not listed in the available bluetooth devices, check the power, unplug and plug the sensor back in to reset it, and try again.</li>
          <li>If the application is not receiving updated (or any) readings, check the sensor’s port and make sure it is clear of any dirt or debris. The Twig sensor can be lightly rinsed with water. Do not scrub the sensor. Allow it to dry before plugging it back in. </li>
          <li>If your sensor is showing a red light, it is not connected to the internet. Check your internet connection, unplug and plug your sensor back in to reset it.</li>
          <li>If your sensor is blinking Blue, it has an error. Unplug and plug your sensor back in to reset it, and contact us if the issue persists. </li>
        </ol>
        
        <h2>Contact us</h2>
        <p>If you need any assistance at all please contact us at <a className="download-text" href={`mailto:${JUNIPER_SUPPORT_EMAIL}`}>{JUNIPER_SUPPORT_EMAIL}</a>. We welcome any and all feedback and would love to hear from you.</p>

      </div>
    </div>
  )
}
