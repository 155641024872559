import ApplicationRecord from "./application";
import { attr, belongsTo } from "spraypaint/dist/spraypaint";

const AlertLog = ApplicationRecord.extend({
  static: {
    jsonapiType: "alert_logs"
  },
  attrs: {
    alert: belongsTo(),
    customerDevice: belongsTo(),
    message: attr(),
    timestamp: attr(),
    hasBeenRead: attr(),
    createdAt: attr()
  },
});

export { AlertLog };
