import ApplicationRecord from "./application";
import { attr, belongsTo } from "spraypaint/dist/spraypaint";

const RawSensor = ApplicationRecord.extend({
  static: {
    jsonapiType: "raw_sensor_readings",
    latest: function () {
      return this.order({ timestamp: "desc" });
    },
  },
  attrs: {
    timestamp: attr(),
    name: attr(),
    value: attr(),
    unit: attr(),
    customerDevice: belongsTo(),
    createdAt: attr({ persist: false }),
    updatedAt: attr({ persist: false })
  },
});

export { RawSensor };
