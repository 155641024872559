import { useState, useEffect } from "react";
import {
  Button,
  Heading,
  Pane,
  SideSheet,
  TextInputField,
  toaster,
} from "evergreen-ui";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router";
import { User } from "../models/user";

const UserSchema = Yup.object().shape({
  name: Yup.string().required("User name is required!"),
  email: Yup.string().email().required("Email is required!"),
});

export default function EditUserSideSheet() {
  let { id } = useParams();
  const history = useHistory();
  const [selUser, setUser] = useState(null);
  useEffect(() => {
    if (id) getUserData(id);
  }, [id]);

  async function getUserData(id) {
    let data = await User.includes("memberships").where({ id }).first();
    setUser(data.data);
  }
  if (!selUser) return null;
  return (
    <SideSheet isShown={true} onCloseComplete={() => history.goBack()}>
      <Pane margin={20}>
        <Heading size={800}>Edit: {selUser.name}</Heading>
        <Pane display="flex" flexDirection="column" marginTop={25}>
          <Formik
            initialValues={{
              name: selUser.name,
              email: selUser.email,
            }}
            validationSchema={UserSchema}
            onSubmit={async (values) => {
              let subUser = (await User.find(id)).data;
              subUser.name = values.name || subUser.name;
              subUser.email = values.email || subUser.email;

              await subUser.save();
              if (subUser.errors.base) {
                toaster.warning(subUser.errors.base.message);
              }

              toaster.success(`${subUser.name} has been updated!`);
              history.goBack();
            }}
          >
            {(props) => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  <TextInputField
                    id="name"
                    name="name"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.name}
                    validationMessage={props.errors.name || null}
                    label="Name:"
                    placeholder={props.initialValues.name}
                    type="text"
                  />
                  <TextInputField
                    id="email"
                    name="email"
                    onChange={props.handleChange}
                    isInvalid={!!props.errors.email}
                    validationMessage={props.errors.email || null}
                    label="Email:"
                    placeholder={props.initialValues.email}
                    type="text"
                  />
                  <Pane display="flex" justifyContent="center">
                    <Button type="submit">Submit</Button>
                  </Pane>
                </Form>
              );
            }}
          </Formik>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
