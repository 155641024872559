import { PrismCode } from "react-prism"

const HTTPText = ({id}) => (
  <>
    <p>For Browser Based HTTP</p>
    <PrismCode component="pre" className="language-javascript">
      {`
              let data = {
                id: "${id}",
                timestamp: Date.now() / 1000,
                readings: [
                  {
                    name: "the name of measurement",
                    value: "the float value of the measurement",
                    unit: "the unit of the measurement"
                  },
                  {
                    name: "the name another measurement at same time interval",
                    value: "the float value another measurement at same time interval",
                    unit: "the unit another measurement at same time interval"
                  }
                ],
              }

              let options ={
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
              }

              await fetch('https://ingestion.junipertechnology.co/sensor-ingest', options)
            `}
    </PrismCode>
  </>
)

export default HTTPText