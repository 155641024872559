import { Pane } from "evergreen-ui";
import PrimarySideBarNavigation from "../shared/PrimarySidebarNavigation";
import { Switch, Route } from "react-router-dom";
import DevicesPage from "../pages/Devices/Devices";
import OrganizationsPage from "../pages/Organizations";
import UsersPage from "../pages/Users/Users";
import DashboardPage from "../pages/Dashboard";
import CurrentUser from "../hooks/currentUser";
import AreasPage from "../pages/Areas/Areas";
import SettingsPage from "../pages/Settings/SettingsPage";
import { DeviceStore, DeviceContext } from "../pages/Devices/DeviceStore";
import { GlobalContext, GlobalStore } from '../store/globalStore';
import TopNavigation from "../shared/TopNavigation";
import NotificationPage from '../pages/Notification';
import { AppDownloadBanner } from "../components/banners/appDownloadBanner";

export default function DashboardLayout() {
  const user = CurrentUser();
  const Store = new DeviceStore();
  const globalStore = new GlobalStore();
  if (!user) return null;
  return (
    <GlobalContext.Provider value={globalStore}>
      <DeviceContext.Provider value={Store}>
        <Pane display="flex">
          <PrimarySideBarNavigation />
          <Pane
            display="flex"
            flexDirection="column"
            flex="1"
            overflowY="hidden"
            backgroundColor="#F1F2F6"
            >
            <AppDownloadBanner />
            <TopNavigation />
            <Pane paddingX="1rem">
              <Switch>
                <Route exact path="/dashboard">
                  <DashboardPage />
                </Route>
                <Route path="/dashboard/devices">
                  <DevicesPage />
                </Route>
                <Route path="/dashboard/areas">
                  <AreasPage />
                </Route>
                <Route path="/dashboard/organizations">
                  <OrganizationsPage />
                </Route>
                <Route path="/dashboard/users">
                  <UsersPage />
                </Route>
                <Route path="/dashboard/settings">
                  <SettingsPage />
                </Route>
                <Route path="/dashboard/notifications">
                  <NotificationPage />
                </Route>
              </Switch>
            </Pane>
          </Pane>
        </Pane>
      </DeviceContext.Provider>
    </GlobalContext.Provider>
  );
}
