import {
  TextInputField,
  Button,
  Checkbox,
} from "evergreen-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import "./Login.css"

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Please enter valid email").required("Email is required."),
  password: Yup.string().min(2, "Too Short!").required("Password is required!"),
});

export default function LoginForm({ handleSubmit }) {
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          remember_me: false,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => {
          return (
            <Form onSubmit={props.handleSubmit}>
              <TextInputField
                id="email"
                name="email"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                isInvalid={ props.touched.email && props.errors.email}
                validationMessage={props.touched.email && props.errors.email ? props.errors.email : null}
                label={(<p style={{ margin: 0, fontSize: 12, color:"#343A40" }}>Email</p>)}
                placeholder="jane@acme.com"
              />
              <TextInputField
                id="password"
                name="password"
                type="password"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                isInvalid={props.errors.password && props.touched.password}
                validationMessage={props.errors.password && props.touched.password ? props.errors.password : null}
                label={(<p style={{ margin: 0, fontSize: 12, color:"#343A40" }}>Password</p>)}
                placeholder="******"
              />
              <Checkbox
                name="remember_me"
                label="Remember Me"
                checked={props.values.remember_me}
                onChange={props.handleChange}
              />
              <Button
                type="submit"
                className="login-button"
              >
                  Submit
              </Button>

            </Form>
          );
        }}
      </Formik>
    </>
  );
}
