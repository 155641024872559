import { Switch, Route } from "react-router-dom";
import LandingPage from "../pages/Landing";
import CommonFooter from '../shared/CommonFooter';

export default function LandingLayout() {

  return (
    <Switch>
      <Route exact path="/">
        <LandingPage />
        <CommonFooter>
          <h2 style={{ color: "#ffffff" }} className="h5">Juniper Technology, LLC - 2022</h2>
        </CommonFooter>
      </Route>
    </Switch>
  );
}
