import url from '../utils/base_url'
const { SpraypaintBase } = require("spraypaint/dist/spraypaint");

export default SpraypaintBase.extend({
  static: {
    baseUrl: url,
    apiNamespace: "/api/v1",
    generateAuthHeader: function (token) {
      return "Bearer " + token;
    },
  },
});
