import ApplicationRecord from "./application";
import { attr } from "spraypaint/dist/spraypaint";

const Credentials = ApplicationRecord.extend({
  static: {
    jsonapiType: "credentials",
  },
  attrs: {
    email: attr(),
    password: attr(),
    jsonWebToken: attr(),
  },
});

export { Credentials };
