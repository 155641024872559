import React, { useCallback, useEffect, useState } from "react";

import { Spinner } from "evergreen-ui";
import { Sensor } from "../../models/sensor";
import moment from "moment-timezone";

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./tooltip.css";
function CustomToolTip({ active, payload, data }) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <h6 className="label">
          {moment(payload[0].payload.timestamp).format("ll")}
        </h6>
        <h6 className="label">
          {moment(payload[0].payload.timestamp).format("LTS")}
        </h6>

        <p className="intro" style={{ color: payload[0].color }}>
          {payload[0].dataKey}: {payload[0].payload[payload[0].dataKey]}{" "}
          {payload[0].unit}
        </p>
      </div>
    );
  }
  return null;
}

function SensorChart(props) {
  const [loading, setLoading] = useState(true);
  const [sensorData, setSensorData] = useState(null);

  const loadSensorData = useCallback(async () => {
    const { sensor, customerDevice } = props;
    const { data: sensorReadings } = await Sensor.where({
      name: sensor.name,
      customer_device_id: customerDevice.id,
    })
      .page(0)
      .per(50)
      .order({ timestamp: "desc" })
      .all();

    const groupedValues = sensorReadings.reverse().map((reading) => {
      return {
        timestamp: Date.parse(reading.timestamp),
        [`${reading.name}`]: parseFloat(reading.value),
        amt: reading.value,
      };
    });
    setLoading(false);
    setSensorData(groupedValues);
  }, [props]);

  useEffect(() => {
    setLoading(true);
    loadSensorData();
  }, [loadSensorData, props.sensor]);

  return (
    <>
      {loading && (
        <>
          <Spinner size={32} />
        </>
      )}
      {!loading && !sensorData?.length === 0 && <h4>No data yet.</h4>}
      {!loading && sensorData?.length > 0 && (
        <div style={{ display: "flex", flex: 1, height: "5rem", width: 700 }}>
          <LineChart
            width={700}
            height={350}
            data={sensorData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>

            <Tooltip content={<CustomToolTip />} />
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="timestamp"
              tickFormatter={(time) => moment(time).format("LTS")}
            />
            <YAxis type="number" yAxisId="1" domain={["auto", "auto"]} />
            <Legend />
            <Line
              yAxisId="1"
              type="linear"
              dataKey={props.sensor.name}
              stroke="#8884d8"
              animationDuration={300}
              unit={props.sensor.unit}
            />
          </LineChart>
        </div>
      )}
    </>
  );
}

export default SensorChart;
