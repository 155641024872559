import CommonCard from "../../shared/CommonCard";
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

function GenericInfoPanel ({ label, count = 0, commonCardProps, ...otherProps}){
  return (
    <Pane
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      marginBottom="1rem"
      {...otherProps}
    >
      <CommonCard {...commonCardProps}>
        <h2 className="device-card-label">{label}</h2>
        <p className="device-card-value">{isNaN(count) ? count : <CountUp end={count} duration={0.40}/>}</p>
      </CommonCard>
    </Pane>
  );
}

GenericInfoPanel.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.any.isRequired,
  commonCardProps: PropTypes.object,
  otherProps: PropTypes.any
}

GenericInfoPanel.defaultProps = {
  count: 0
}

export default GenericInfoPanel