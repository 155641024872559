import ApplicationRecord from './application'
import { attr } from 'spraypaint/dist/spraypaint'

const Registration = ApplicationRecord.extend({
  static: {
    jsonapiType: 'registrations',
  },
  attrs: {
    name: attr(),
    email: attr(),
    password: attr(),
    passwordConfirmation: attr(),
  },
})

export { Registration }
