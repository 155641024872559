import { Pane, Card } from "evergreen-ui";

import LinksTreeBtn from "../components/buttons/linksTreeBtns";
import "./Links.css"
import logo from "../assets/images/Juniper_Icon_Green.svg";
import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from "../utils/constants";

export default function LinksPage() {

  return (
    <Pane
      className="links-pane"
    >
      <Card
        className="links-card"
      >
        <Pane  className="logo-container">
          <img src={logo} height={80} alt="Juniper Technology Logo"/>
        </Pane>
        <LinksTreeBtn
          variant="primary" 
          text="SETUP & SUPPORT" 
          path={"/support"} 
        />
        <LinksTreeBtn
          external
          variant="secondary" 
          text="DOWNLOAD IOS APP" 
          path={ IOS_DOWNLOAD_LINK } 
        />
        <LinksTreeBtn
          external
          variant="secondary" 
          text="DOWNLOAD ANDROID APP" 
          path={ ANDROID_DOWNLOAD_LINK } 
        />
      </Card>
    </Pane>
  );
}
