import { TextInputField } from "evergreen-ui"
import { useMemo } from "react"

function EmailInput(props) {
  return (
    <TextInputField
      label="Email"
      type="email"
      description="This is the email address that will be notified when the alert is triggered."
      validationMessage={props.validationMessage}
      defaultValue={props.defaultValue}
      onChange={event => props.setFieldValue('notificationPayload', event.target.value)}
    />
  )
}

const alertTypeInputComponents = {
  email: EmailInput,
}


export default function AlertTypeInput(props) {
  const AlertTypeInput = useMemo(() => alertTypeInputComponents[props.alertType], [props.alertType])
  if(!AlertTypeInput) return <p role="alert">That Alert Type doesn't exist</p>

  return <AlertTypeInput {...props} />
}