import { PrismCode } from "react-prism"

const ModdableSdkText = ({id}) => (
  <>
    <p>For Moddable SDK</p>
    <PrismCode component="pre" className="language-javascript" >
      {`
              let request = new Request({
                host: "ingestion.junipertechnology.co",
                path: "/sensor-ingest",
                method: "PUT",
                port: 80,
                headers: ["Content-Type", "application/json"],
                body: JSON.stringify({
                  id: "${id}",
                  timestamp: Date.now() / 1000,
                  readings: [
                    {
                      name: "the name of measurement",
                      value: "the float value of the measurement",
                      unit: "the unit of the measurement"
                    },
                    {
                      name: "the name another measurement at same time interval",
                      value: "the float value another measurement at same time interval",
                      unit: "the unit another measurement at same time interval"
                    }
                  ]
                }),
                response: String,
              });

              request.callback = function (message, value, val2) {
                trace(message, value, val2)
                /* handle response */
                return null;
              };
            `}
    </PrismCode>
  </>
)

export default ModdableSdkText