import {
  Pane,
  toaster,
  Card,
} from "evergreen-ui";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import LoginForm from "./LoginForm";
import { Credentials } from '../../models/credential';
import ApplicationRecord from '../../models/application';
import { AppDownloadBanner } from "../../components/banners/appDownloadBanner";
import { tokenWithPrefix } from '../../hooks/currentUser';

import "./Login.css"
import logo from "../../assets/images/Juniper_Badge_Outline_Black.svg";

export default function LoginPage() {
  const history = useHistory();

  const handleSubmit = async (values) => {
    
    let creds = new Credentials({
      password: values.password,
      email: values.email
    });
    
    try {
      await localStorage.setItem('token', null);
      ApplicationRecord.jwt = null;
  
      await creds.save();
      if (creds.errors?.base) {
        toaster.warning(creds.errors.base.message);
        return;
      }
      await localStorage.setItem(tokenWithPrefix, creds.jsonWebToken);
  
      ApplicationRecord.jwt = creds.jsonWebToken;
      history.push("/dashboard");
    } catch (error) {
      toaster.warning('There was a server error. Please try again later.');
    }
  };
  

  return (
    <>
      <AppDownloadBanner />
      <Pane
        className="login-pane"
      >
        <Card
          className="login-card"
          elevation={2}
        >
          <Pane
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop={60}
            marginBottom={32}
          >
            <img src={logo} height={39} width={33} alt="Juniper Technology Logo"/>
            <h1 style={{ margin: 10}}>Welcome</h1>
            <p style={{ color: "#868E96", textAlign: 'center', margin: 10}}>Enter your credentials to access your account</p>
          </Pane>
          <LoginForm handleSubmit={handleSubmit}/>
          <p className="register-text">Dont have an account? <Link to="/register" className="reset-password-link">Sign up now</Link></p>
          {/* <p className="forgot-password-text">Forgot your password? <Link to="/reset-password" className="reset-password-link">Reset password</Link></p> */}
        </Card>

      </Pane>
    </>
  );
}
