import { createContext } from 'react'
import { makeAutoObservable, observable, runInAction } from 'mobx'
import { CustomerDevice } from '../../models/customer_devices'
import { Sensor } from '../../models/sensor'
import { Alert } from '../../models/alerts'
export const DeviceContext = createContext()

export class DeviceStore {
  loading = false
  device = {}

  constructor() {
    makeAutoObservable(this, {
      device: observable.struct,
      loading: observable,
    })
  }

  async getDevice(id) {
    runInAction(async () => {
      this.loading = true

      let alertsScope = Alert.active();
      let sensorScope = Sensor.latest();

      let cd = await CustomerDevice.includes([
        "alerts",
        "organization",
        "sensor_readings",
        "device_claim",
        "categories"
      ])
        .selectExtra(['sensorTypeOptions'])
        .merge({
          sensor_readings: sensorScope,
          alerts: alertsScope
        })
        .find(id);
      this.device = cd
      this.loading = false
    })
  }

  async refreshDevice() {
    runInAction(async () => {
      if (!this.device.id) return false
      this.loading = true
      this.device = null
      let alertsScope = Alert.active();
      let sensorScope = Sensor.latest();

      let cd = await CustomerDevice.includes([
        "alerts",
        "organization",
        "sensor_readings",
        "categories"
      ])
        .merge({
          sensor_readings: sensorScope,
          alerts: alertsScope
        })
        .find(this.device.id);
     
      this.device = cd
      this.loading = false
    })
  }
}
