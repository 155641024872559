import ApplicationRecord from "./application";
import { attr } from "spraypaint/dist/spraypaint";

const User = ApplicationRecord.extend({
  static: {
    jsonapiType: "users",
  },
  attrs: {
    email: attr(),
    name: attr(),
    nickname: attr(),
    stripe_cust_id: attr(),
    stripe_charges: attr(),
    subscriptions: attr({ persist: false }),
    createdAt: attr({ persist: false }),
    updatedAt: attr({ persist: false }),
  },
  methods: {
    plusMember: function() {
      return this.attributes.subscriptions.length > 0 || false
    }
  }
});

export { User };
