import { Button, EyeOpenIcon, IconButton, majorScale, Pane, PlusIcon, Table } from "evergreen-ui";
import { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";
import { User } from "../../models/user";
import EditUserSideSheet from "../../shared/EditUserSidesheet";
import CommonTopPageHeader from '../../shared/CommonTopPageHeader';
import GenericInfoPanel from '../../components/info-panels/generic-info-panel';

export default function UsersPage() {
  const location = useLocation();
  const [userCount, setUserCount] = useState(0);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUserData();
  }, [location.pathname]);

  async function getUserData() {
    let data = await User.stats({ total: "count" }).all();
    setUserCount(data.meta.stats.total.count);
    setUsers(data.data);
  }

  return (
    <Pane display="flex" flex="1" flexDirection="column">
      <CommonTopPageHeader>
        <h1 style={{ margin: 0, verticalAlign: "middle" }}>
            Users Page
        </h1>
        <div>
          <Button
            iconBefore={PlusIcon}
            intent="none"
            onClick={() => {}}
            marginLeft={10}
            height={42}
          >
              Invite User
          </Button>
        </div>
      </CommonTopPageHeader>
      <GenericInfoPanel label={"Users"} count={userCount} />
      {users.length > 0 && <UsersList users={users} />}
      <Route path="/dashboard/users/:id/edit" component={EditUserSideSheet} />
    </Pane>
  );
}

function UsersList({ users }) {
  return (
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>User Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>User Email</Table.TextHeaderCell>
        <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body flex="1">{users.map(UsersRow)}</Table.Body>
    </Table>
  );
}

function UsersRow(user) {
  const history = useHistory();
  return (
    <Table.Row
      key={user.id}
      isSelectable
    >
      <Table.TextCell>{user.name}</Table.TextCell>
      <Table.TextCell>{user.email}</Table.TextCell>
      <Table.TextCell>
        <IconButton
          icon={(iconProps) => (
            <EyeOpenIcon
              {...iconProps}
              tab-index="-1"
              aria-label={`Edit User ${user.name}`}
            />
          )}
          marginRight={majorScale(2)}
          intent="none"
          onClick={() => history.push(`/dashboard/users/${user.id}/edit`)}
        />
      </Table.TextCell>
    </Table.Row>
  );
}
