import {
  Pane,
  MenuIcon
} from "evergreen-ui";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import classNames from "classnames";

import "./PrimarySidebarNavigation.css";
import HomeDuotoneIcon from "../assets/icons/home/home_duotone";
import HomeIcon from "../assets/icons/home/home";
import DeviceDuotoneIcon from "../assets/icons/devices/device_duotone";

import UsersDuotoneIcon from "../assets/icons/users/users_duotone";
import SettingsDuotoneIcon from "../assets/icons/settings/settings_duotone";
import SettingsIcon from "../assets/icons/settings/settings";
import LogoutIcons from "../assets/icons/logout/logout";
import AreasDuotoneIcon from '../assets/icons/areas/areas_duotone';
import AreasFilledIcon from '../assets/icons/areas/areas_filled';
import UsersFilledIcon from '../assets/icons/users/users_filled';
import DeviceFilledIcon from '../assets/icons/devices/device_filled';
import HomeFilledIcon from '../assets/icons/home/home_filled';

function PrimarySideBarNavigation(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Pane
      paddingTop={30}
      paddingBottom={10}
      flexDirection="column"
      alignItems="center"
      background="#212529"
      minHeight="100vh"
    >
      <IconTab
        onClick={() => setIsExpanded(!isExpanded)}
        isSelected={false}
        expanded={isExpanded}
        title="Expand Menu"
      >
        <MenuIcon
          color={"#fff"}
          height="100%"
          display="flex"
          flex={"1"}
          margin={0}
          padding={0}
          
        />
      </IconTab>
      <IconTab
        onClick={() => props.history.push(`/dashboard`)}
        isSelected={props.location.pathname === "/dashboard"}
        expanded={isExpanded}
        label="Dashboard"
      >
        <div style={{ display: "flex", flex: 1 }} title="Dashboard Route">
          {props.location.pathname === "/dashboard" ? (
            <HomeDuotoneIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          ) : (
            <HomeFilledIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          )}
        </div>
        {/* <HomeIcon
          color={props.location.pathname === '/dashboard' ? "rgba(113, 180, 141, 0.39)" :  "#fff" }
          height="100%"
          display="flex"
          flex={isExpanded ? "0.5": "1"}
          margin={0}
          padding={0}
        /> */}
      </IconTab>
      <IconTab
        onClick={() => props.history.push(`/dashboard/devices`)}
        isSelected={props.location.pathname.includes("devices")}
        expanded={isExpanded}
        label="Devices"
      >
        <div style={{ display: "flex", flex: 1 }} title="Devices Route">
          {props.location.pathname.includes("devices") ? (
            <DeviceDuotoneIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
            
            />
          ) : (
            <DeviceFilledIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          )}
        </div>
      </IconTab>
      <IconTab
        onClick={() => props.history.push(`/dashboard/areas`)}
        isSelected={props.location.pathname.includes("areas")}
        expanded={isExpanded}
        label="Areas"
      >
        <div style={{ display: "flex", flex: 1 }} title="Areas Route">
          {props.location.pathname.includes("areas") ? (
            <AreasDuotoneIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          ) : (
            <AreasFilledIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          )}
        </div>
      </IconTab>
      {/* <IconTab
        onClick={() => props.history.push(`/dashboard/organizations`)}
        isSelected={props.location.pathname.includes("organizations")}
        expanded={isExpanded}
        label="Organizations"
      >
        <div style={{ display: "flex", flex: 1 }} title="Organizations Route">
          {props.location.pathname.includes("organizations") ? (
            <HomeDuotoneIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          ) : (
            <HomeIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          )}
        </div>
      </IconTab> */}
      {/* 
      <IconTab
        onClick={() => props.history.push(`/dashboard/users`)}
        isSelected={props.location.pathname.includes("users")}
        expanded={isExpanded}
        label="Users"
      >
        <div style={{ display: "flex", flex: 1 }} title="Users Route">
          {props.location.pathname.includes("users") ? (
            <UsersDuotoneIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          ) : (
            <UsersFilledIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          )}
        </div>
      </IconTab> */}
      <IconTab
        onClick={() => props.history.push(`/dashboard/settings`)}
        isSelected={props.location.pathname.includes("settings")}
        expanded={isExpanded}
        label="Settings"
      >
        <div style={{ display: "flex", flex: 1 }} title="Settings Route">
          {props.location.pathname.includes("settings") ? (
            <SettingsDuotoneIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          ) : (
            <SettingsIcon
              width={"1.5rem"}
              height={"1.5rem"}
              style={{ display: "flex", flex: 1 }}
              
            />
          )}
        </div>
      </IconTab>
      <IconTab
        onClick={async () => {
          await localStorage.clear();
          props.history.push(`/login`);
        }}
        isSelected={false}
        expanded={isExpanded}
        label="Sign Out"
      >
        <div style={{ display: "flex", flex: 1 }} title="Logout Button">
          <LogoutIcons
            width={"1.5rem"}
            height={"1.5rem"}
            style={{ display: "flex", flex: 1 }}
            
          />
        </div>
      </IconTab>
    </Pane>
  );
}
function IconTab(props) {
  const classes = classNames({
    "icon-tab": true,
    active: props.isSelected,
    "expanded-tab": props.expanded,
    "align-center": true,
  });
  return (
    <div className={classes} onClick={props.onClick}>
      {props.children}
      {props.label && (
        <p
          className={classNames({
            label: true,
            active: props.isSelected,
            hidden: !props.expanded,
          })}
        >
          {props.label}
        </p>
      )}
    </div>
  );
}

export default withRouter(PrimarySideBarNavigation);
