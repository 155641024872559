import ApplicationRecord from "./application";
import { attr } from "spraypaint/dist/spraypaint";

const DeviceClaims = ApplicationRecord.extend({
  static: {
    jsonapiType: "device_claims",
  },
  attrs: {
    customerDeviceId: attr(),
    juniperDeviceId: attr()
  },
});

export { DeviceClaims };