import { Pane } from "evergreen-ui";
import { Link, Route, Switch } from "react-router-dom";
import CurrentUser from "../../hooks/currentUser";
import CommonTopPageHeader from "../../shared/CommonTopPageHeader";
import "./SettingsPage.css";
import UserInformation from "./UserSettings/UserInformation";
import classNames from 'classnames';
import { useLocation } from 'react-router';
import InternalCredentials from "./Credentials/InternalCredentials";
import CreateOrganizationCredentialSideSheet from "../../shared/CreateOrganizationCredentialSidesheet";
import Billing from "./UserSettings/Billing";

export default function SettingsPage() {
  const user = CurrentUser();
  const location = useLocation()
  if (!user) return null;
  return (
    <Pane
      display="flex"
      flex="1"
      flexDirection="column"
      overflowY="scroll"
      height="100vh"
      className="hidden-scrollbar"
    >
      <CommonTopPageHeader>
        <h1 style={{ margin: 0, verticalAlign: "middle" }}>
          Settings:{" "}
          {user.attributes.name ||
            user.attributes.email}
        </h1>
      </CommonTopPageHeader>
      <Pane
        display="grid"
        gridTemplateColumns="0.8fr 4fr"
        gridGap="1rem"
        height="100%"
        backgroundColor="#fff"
      >
        <Pane
          display="flex"
          flexDirection="column"
          flex="1"
          borderRight="2px solid #F1F2F6"
          className="settings-sidebar"
        >
          <ul>
            <li className={classNames({
              'active': location.pathname === "/dashboard/settings/profile"
            })}>
              <Link to="/dashboard/settings/profile">Profile</Link>
            </li>

            <li className={classNames({
              'active': location.pathname === "/dashboard/settings/credentials"
            })}>
              <Link to="/dashboard/settings/credentials">Credentials</Link>
            </li>
            <li className={classNames({
              'active': location.pathname === "/dashboard/settings/billing"
            })}>
              <Link to="/dashboard/settings/billing">Billing</Link>
            </li>
          </ul>
        </Pane>
        <Pane paddingY="1rem" paddingX="1rem">
          <Switch>
            <Route path="/dashboard/settings/profile">
              <UserInformation />
            </Route>
            <Route path="/dashboard/settings/billing">
              <Billing />
            </Route>
            <Route path="/dashboard/settings/credentials">
              <InternalCredentials />
            </Route>
          </Switch>
          
        </Pane>
      </Pane>
      <Route exact path="/dashboard/settings/credentials/new" component={CreateOrganizationCredentialSideSheet} />
    </Pane>
  );
}
