import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import DashboardLayout from "./layouts/dashboard";
import LoginPage from "./pages/Login/Login";
import LinksPage from "./pages/Links";
import RegistrationPage from "./pages/Registration/Registration";
import SupportPage from "./pages/Support";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import LandingLayout from "./layouts/landing";

require("prismjs");
require("prismjs/themes/prism.css");

function App() {
  useEffect(() => {
    process.env.REACT_APP_ENVIRONMENT === "production" &&
      hotjar.initialize(
        process.env.REACT_APP_HOTJAR_HJID,
        process.env.REACT_APP_HOTJAR_HJSV
      );
  }, []);
  return (
    <main>
      <Router>
        <Switch>
          <Route path="/cancel" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/dashboard">
            <DashboardLayout />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/links/:deviceID?">
            <LinksPage />
          </Route>
          <Route path="/register">
            <RegistrationPage />
          </Route>
          <Route path="/support">
            <SupportPage />
          </Route>
          <Route path="/">
            {/*Temporary Reroute*/}
            {/* <LoginPage /> */}
            <LandingLayout />
          </Route>
        </Switch>
      </Router>
    </main>
  );
}

export default App;
