/* eslint-disable react-hooks/exhaustive-deps */
import {
  Pane
} from "evergreen-ui";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from 'lodash';
import ActionCable from "action-cable-react-jwt";

import CurrentUser, { tokenWithPrefix } from '../../../hooks/currentUser';
import currentSubUrl from "../../../utils/subscription_url";
import "./DeviceLog.css"
import { DeviceContext } from "../DeviceStore";
export default function DeviceLogs() {
  const mobxDevice = useContext(DeviceContext)
  const { device } = mobxDevice

  const [cable, setCable] = useState(null);
  const params = useParams();
  const [logs, setLogs] = useState([])

  useEffect(() => {
    if (!params.id || cable) return;
    if(!device) mobxDevice.getDevice(params.id);
    let userToken = localStorage.getItem(tokenWithPrefix);
    let App = {};
    App.cable = ActionCable.createConsumer(
      `${currentSubUrl}/cable`,
      userToken
    );
    setLogs(oldArray => [...oldArray, 'Connecting to Device...'])
    
    const id = device?.data.deviceClaim ? device?.data.deviceClaim.juniperDeviceId : params.id;
    App.subscription = App.cable.subscriptions.create(
      { channel: "DeviceLogsChannel", id },
      {
        connected: () => {
          setLogs(oldArray => [...oldArray, "connected to server, waiting on device..."])
        },
        disconnected: () => {
          setLogs(oldArray => [...oldArray, "disconnect from server,refresh page or check device connection"])
        },
        received: (data) => {
          try {
            setLogs(oldArray => [...oldArray, data.log])
          } catch(err) {
            setLogs(oldArray => [...oldArray, 'Error: couldnt parse data'])
          }
        }
      }
    );

    return () => {
      if(App?.subscription) {
        App?.cable?.subscriptions?.remove(App.subscription);
        App.cable.disconnect();
      }
    }
  }, [device]);

  return (
    <Pane className="device-logs-container" backgroundColor="#000000" padding={'1rem'} overflowY="scroll" height="100vh">
      {logs?.map(LogText)}
    </Pane>
  )
}

function LogText(log, index) {
  return <section key={index + Math.random()}><p>[{index}]</p><p>{log}</p></section>
}