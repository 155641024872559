import { TextInputField } from "evergreen-ui";
import { Formik, Form } from "formik";
import CurrentUser from "../../../hooks/currentUser";

export default function UserInformation(props) {
  const user = CurrentUser();
  if (!user) return null;

  return (
    <Formik
      initialValues={{
        name: user.attributes.name,
        email: user.attributes.email,
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <TextInputField
            id="name"
            name="name"
            onChange={handleChange}
            isInvalid={!!errors.name}
            disabled
            validationMessage={errors.name || null}
            label={
              <p style={{ margin: 0, fontSize: 12, color: "#343A40" }}>Name</p>
            }
            defaultValue={values.name}
            placeholder="Jane Doe"
          />
          <TextInputField
            id="email"
            name="email"
            onChange={handleChange}
            disabled
            isInvalid={!!errors.email}
            validationMessage={errors.email || null}
            label={
              <p style={{ margin: 0, fontSize: 12, color: "#343A40" }}>Email</p>
            }
            placeholder="jane@acme.com"
            defaultValue={values.email}
          />
        </Form>
      )}
    </Formik>
  );
}

